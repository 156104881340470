import React, { Component } from 'react';
import axios from 'axios'; // Import axios for making API calls

class OTPVerificationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '', // Initialize the state variables for email and OTP
            otp: '',
            msg: "We've sent a code to"
        };
    }

    // Event handler for changing the OTP input field
    handleOtpChange = (event) => {
        const otpValue = parseInt(event.target.value); // Convert input value to a number
        this.setState({ otp: otpValue });
    };


    // Event handler for clicking the "Verify" button
    handleVerifyClick = async () => {
        // const currentUrl = window.location.href;

        const { email, userdata } = this.props;
        const { otp } = this.state;
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/user/verify-otp`,
                {
                    email: email,
                    otp: otp,
                }
            );// Handle the successful response here
            if (response.data.status === "Success") {
                if (userdata) {
                    localStorage.setItem("token", userdata.jwt);
                    localStorage.setItem("zc_userMobile", userdata.data.mobile);
                    localStorage.setItem("zc_userEmail", userdata.data.email)
                    window.location.href = "#/invoices";
                }else{
                    window.location.href = `#/Login`;
                }
                // this.props.history.push("/Login");
            }
            console.log(response.data); // You can log or handle the response data accordingly
        } catch (error) {
            // Handle errors from the API call
            console.log(error);
        }
    };

    // Event handler for clicking the "Resend" link
    handleResendClick = async () => {
        const { email } = this.props;
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/user/resend-otp`,
                {
                    email: email,
                }
            );
            // Handle the successful response here
            console.log(response.data); // You can log or handle the response data accordingly
            if (response.data.status === 'Success') {
                this.setState({
                    msg: "We've sent again code to " + email
                })
            }
            // If you want to update the UI, you can reset the OTP state or display a message here
            // Example: this.setState({ otp: '' }); or show a success message
        } catch (error) {
            // Handle errors from the API call
            console.log(error);
        }
    };

    // Event handler for clicking the "Cancel" button
    handleCancelClick = () => {
        // Implement the logic to close the modal or navigate to another page
    };

    render() {
        const { otp } = this.state;
        const { email } = this.props;
        return (
            <div id="modal">
                <div className="modal-body">
                    <div className="row mx-auto justify-content-center">
                        <div className="col-md-3 mt-5">
                            <div className="bg-white p-5 rounded-3 shadow-sm border">
                                <div>
                                    <p className="text-center text-success" style={{ fontSize: '5.5rem' }}>
                                        <i className="fa-solid fa-envelope-circle-check"></i>
                                    </p>
                                    <p className="text-center text-center h5">Please check your email</p>
                                    {/* <p className="text-muted text-center">We've sent a code to {email}</p> */}
                                    {this.state.msg && <p className="text-muted text-center">{this.state.msg} {email}</p>}

                                    <div className="row pt-4 pb-2">
                                        <input
                                            type="number"
                                            className="otp-letter-input"
                                            name="otp"
                                            id="otp"
                                            value={otp}
                                            onChange={this.handleOtpChange}
                                        />
                                    </div>
                                    <p className="text-muted text-center">
                                        Didn't get the code?{' '}
                                        <div className="text-success" onClick={this.handleResendClick}>
                                            Click to resend.
                                        </div>
                                    </p>
                                    <div className="row pt-5">
                                        {/* <div className="col-6">
                                            <button className="btn btn-outline-secondary w-100" onClick={this.handleResendClick}>
                                                Resend OTP
                                            </button>
                                        </div> */}
                                        {/* <div className="col-6"> */}
                                        <button className="btn btn-success w-100" onClick={this.handleVerifyClick}>
                                            Verify
                                        </button>
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OTPVerificationModal;
