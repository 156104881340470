import React from "react";

// import Sidebar from "../common/sidebar";
// import TopBar from "../common/top-bar";

const AuthLayout = (props) => {
  return (
    <React.Fragment>
      <main className="main" id="top">
        <div className="container-fluid px-2" data-layout="container">
          {/* <Sidebar /> */}
          <div className="content max-h-screen zc-main-content scrollbar mt-2" style={{ minHeight: "100vh" }}>
            {/* <TopBar /> */}
            {props.children}
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default AuthLayout;
