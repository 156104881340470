import React from "react";
import navigationHook from "../../common/hooks";
import axios from "axios";
import fileDownload from "js-file-download";
import { formateDate } from "../../common/Utils.js";
// import Select from "react-select";
import './invoice.css';
import AddCompanyModal from "../companies/add-debt-cred/add_company";
import Breadcrumb from "../../common/breadcrums";
import { isGSTNo } from "../../common/Utils";
import mixpanel from "mixpanel-browser";


class InvoicesDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseUrl: process.env.REACT_APP_BASE_URL, //"http://localhost:8100/api",
      invoice: {},
      filetype: "image",
      invDate: "",
      company: "",
      loader: true,
      currentInvoice: {},
      invoices: [],
      selectOptions: [],
      selectCredDebt: [],
      selectOptionsBranch: [],
      allClients: [],
      branchIndex: 0,
      credDebtIndex: 0,
      branchUpdateBody: {},
      credDebtUpdateBody: {},
      message: "",
      defaultComapny: "",
      defaultCompany_address: "",
      defaultBranch: "",
      defaultBranchLabel: "",
      dummytext: "vikash",
      cred_debt_type: "",
      // companystate: props.branch.state,
      width: "100%",
      height: "100%",
      scale: 1,
      ApproveLable: "Approve",
      CustomerLabel: "Buyer name",
      CustomerGstLabel: "Customer GST",
      showAddCompanyModal: false,
      setShowAddCompanyModal: false,
      showEditCompanyModal: false,
      setShowEditCompanyModal: false,
      displayAlert: {
        display: false,
        status: false,
        message: "",
      },
      displayAlert2: {
        display: false,
        status: false,
        message: "",
      },
      // this.historyRef = createRef();
      companyId: {},
      isCounterGstChange: false,
      isClientGstChange: false
    };
    console.log(props);
  }


  handleEditModalClick = (id) => {
    this.setState({
      companyId: id,
      showEditCompanyModal: true,
    });
  };
  get_url_extension = (url) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log("GOT ERROR");
    console.log(error);
    return { hasError: true };
  }

  async componentDidMount() {
    try {
      await this.getInvoice();
    } catch (e) {
      console.log(e);
    }
  }

  getAllInvoices = () => {
    console.log(this.state.company);
    axios
      .get(`${this.state.baseUrl}/invoice-all/` + this.state.company, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let data = response.data.data;
        console.log("inv all", response.data);
        const index = data.findIndex(
          (item) => item._id === this.state.invoice._id
        );
          console.log(response.data.status);
        //  if(response) 
        // })
        this.setState({ ...this.state, currentInvoice: index, invoices: data });
        console.log("default branch", this.state.defaultComapny);
        this.checkImageExtension();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((f) => { });
  };


  getAllClients = (opt) => {
    console.log(opt);

    if (opt !== undefined && opt !== null && opt.length >= 3) {
      axios
        .get(`${this.state.baseUrl}/company/all?name=${opt}`, null, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          let data = response.data.data;
          const options = data.map((item, index) => ({
            value: item._id,
            label: item.name,
            branchIndex: index,
            gst: item.branches[0].gst,
            state: item.branches[0].state,
            address: item.branches[0].address,
          }));


          this.setState(
            {
              ...this.state,
              selectOptions: options,
              allClients: data,
              defaultComapny: opt,
            },
            () => {
              this.getBranch();
            }
          );
        })
        .catch((e) => {
          console.log(e);
        })
        .finally((f) => { });
    } else {
      console.log("loading");
    }
  };
  getCreditorDebtors = (opt) => {
    if (opt !== undefined && opt !== null && opt.length >= 2) {
      let body = {
        type: this.state.cred_debt_type,
        company_id: this.state.company,
      }
      axios
        .post(`${this.state.baseUrl}/company/cred-debt/` + 1, body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })

        .then((response) => {
          let data = response.data.data;
          console.log(data);
          const options = data.map((item, index) => ({
            value: item._id,
            label: item.name,
            gst: item.gst,
            address: item.address,
            state: item.state,
            index: index
          }));

          this.setState({
            selectCredDebt: options,

          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      console.log("loading");
    }
  };

  deleteRow = (index) => {
    this.setState((prevState) => ({
      invoice: {
        ...prevState.invoice,
        product_table: prevState.invoice.product_table.filter((_, i) => i !== index),
      },
    }));
  };
  addRow = () => {
    const { invoice } = this.state;
    const productTable = invoice?.product_table || [];
    const newRow = {
      name: " ",
      hsn: " ",
      CGST: " ",
      IGST: " ",
      SGST: " ",
      qty: ' ',
      total: ' '
    };


    const availableFields = (invoice?.product_table.length > 0) ? Object.keys(invoice?.product_table[0]) : Object.keys(newRow);
    const filteredRow = {};
    Object.keys(newRow).forEach((key) => {
      if (availableFields.includes(key)) {
        filteredRow[key] = newRow[key];
      }
    });
    console.log("this " + availableFields);
    console.log(this.state.invoice);

    this.setState({ invoice: { ...invoice, product_table: [...productTable, newRow] } });
  }

  handleInputChangeForClient = async (event) => {
    this.state.branchIndex = event.branchIndex;
    // console.log(this.state.branchIndex);

    // const target = event.target;
    console.log("target handleInputChangeForClient ", event);
    // const value = target.type === "checkbox" ? target.checked : target.value;

    let companybranch = this.state.branchUpdateBody;
    // if (event.client_company === undefined) {
    let client_company = event.label;
    companybranch["client_company"] = client_company;
    // }

    // if (event.client_company_id === undefined) {
    let client_company_id = event.value;
    companybranch["client_company_id"] = client_company_id;
    // }

    console.log("companybranch==", companybranch);

    let _invoice = this.state.invoice;
    _invoice.client_company_id = event.value;
    _invoice.client_company = event.label;
    _invoice.client_company_state = event.state;
    _invoice.client_company_address = event.address;
    _invoice.buyer_gst = event.gst;
    await this.setState({
      loader: true,
      branchUpdateBody: companybranch,
      invoice: _invoice,
    });
    console.log("handle input ", this.state.branchUpdateBody);
  };

  handleInputChangeForCredDebt = async (event) => {

    console.log("target handleInputChangeForCredDebt", event);
    // const value = target.type === "checkbox" ? target.checked : target.value;
    let invoice = this.state.invoice;
    invoice.counter_party_gst = event.gst;
    invoice.counter_party_address = event.address;
    invoice.counter_party_state = event.state;

    console.log("companybranch==", "credDebt");

    await this.setState({
      loader: true,
      invoice: invoice,
      // credDebtUpdateBody: credDebt,
    });
    console.log("handle input ", this.state.credDebtUpdateBody);
  };
  handleInputChangeForBranch = async (event) => {
    // const target = event.target;
    console.log("target handleInputChangeForbranch", event);
    // const value = target.type === "checkbox" ? target.checked : target.value;

    let companybranch = this.state.branchUpdateBody;

    // if (event.company_branch_id === undefined) {
    let company_branch_id = event.value;
    companybranch["company_branch_id"] = company_branch_id;
    // }
    await this.setState({
      loader: true,
      branchUpdateBody: companybranch,
    });
    console.log("handle input ", this.state.branchUpdateBody);
  };

  getBranch = () => {
    console.log("get branch call");

    console.log("all branch" + this.state.allClients)
    let branchOption = this.state.allClients[
      this.state.branchIndex
    ]?.branches.map((branch) => ({
      value: branch._id,
      label: branch.branch_name,
    }));

    let b_label = "";
    branchOption?.map((branch) => {
      if (branch.value === this.state.defaultBranch) {
        b_label = branch.label;
      }
      return branch;
    });

    this.setState(
      {
        loader: true,
        selectOptionsBranch: branchOption,
        defaultBranchLabel: b_label,
      },
      () => {
        // console.log(this.state.defaultBranchLabel)
      }
    );
    // console.log(this.state.selectOptionsBranch)
  };

  companyUpdate = async () => {
    console.log("company update call");

    console.log(this.state.branchUpdateBody);

    let response = await axios.put(
      `${this.state.baseUrl}/invoice/companyupdate/${this.props.params.id}`,
      this.state.branchUpdateBody,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    let data = response.data;
    console.log(response);
    if (data.status === "Success") {
      console.log(data.status);
    }

    this.setState({
      loader: true,
      message: "Success",
    });
  };

  getInvoice = async () => {
    try {
      // console.log("called me");

      let response = await axios.get(
        `${this.state.baseUrl}/invoice/single/${this.props.params.id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      let data = response.data.data;
      console.log(data);
      var clientName = data.client_company;
      // var address = data._invoice.client_company_address;
      let defaultBranch = data.company_branch_id;
      let nameLabel = (data.inv_type === 1 || data.inv_type === '1') ? "Seller" : "Buyer";
      let gstLabel = (data.inv_type === 2 || data.inv_type === '2') ? "Seller GST" : "Customer GST";
      console.log("data", data.inv_date);
      let date = null;
      if (data.inv_date) {
        date = formateDate(data.inv_date);
        data.inv_date = date;
      }
      console.log(date);
      this.setState(
        {
          loader: true,
          invoice: data,
          invDate: date,
          CustomerLabel: nameLabel,
          CustomerGstLabel: gstLabel,
          company: data.client_company_id,
          defaultComapny: clientName,
          defaultBranch: defaultBranch,
          isClientGstChange: false,
          isCounterGstChange: false
          // goods:this.setState
        },
        () => {
          // this.getAllInvoices();
          if (clientName) {
            this.getCompanyDetails();
            this.getAllClients(clientName);
          } else {
            this.getCompanyDetails();
            // console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
          }
        }
      );
      console.log(data.taxes);
    } catch (e) {
      console.log(e);
    }
  };

  getCompanyDetails = async () => {
    try {
      if (this.state.company) {
        console.log("called me !!!!!!!!!!");
        let response = await axios.get(
          `${this.state.baseUrl}/company/${this.state.company}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        let data = response.data.data;
        // console.log(data);
        if (data) {
          let _invoice = this.state.invoice;
          _invoice.client_company_id = data._id;
          _invoice.client_company = data.name;
          _invoice.client_company_state = data.branches[0].state;
          _invoice.client_company_address = data.branches[0].address;
          // _invoice.buyer_gst = data.branches[0].gst;
          this.setState({
            invoice: _invoice,
          });
        }
        this.getAllClients(data.name);
      }
    } catch (e) {
      console.log(e);
    }
  };
  updateInvoice = async (e) => {
    try {
      console.log("update invoice");
      let body = this.state.invoice;


      // let taxes =  body['taxes'];
      console.log(body);

      delete body["_id"];
      delete body["__v"];
      // delete body["client_company_id"];
      // delete body["company_branch_id"];
      // delete body["seller_gst"];
      delete body["doc_url"];
      delete body["createdAt"];
      delete body["updatedAt"];
      delete body["taxes"][0]["_id"];

      // body.status = e.target.attributes.getNamedItem("status").value;
      console.log(body);

      let response = await axios.put(
        `${this.state.baseUrl}/invoice/update/${this.props.params.id}`,
        body,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      let data = response.data;
      console.log(response);
      if (data.status === "Success") {
        console.log(data.status);
        let inv = this.state.invoice;
        inv.status = body.status;

        this.getInvoice();
        // console.log(this.state.invoice);
      }
      console.log(data);
    } catch (e) {
      console.log(e);
    }
  };

  updateStatus = async (e) => {
    mixpanel.init('c45b41f7cb0c1d55172b2375d52dfc5e');
    let email = localStorage.getItem('zc_userEmail');
    
    try {
      console.log("update me");
      console.log(e.target.attributes);
      // console.log(e.target.attributes.getNamedItem("status").value);
      let status = e.target.attributes.getNamedItem("status").value;
      let body = {
        status: status,
      };
      let response = await axios.put(
        `${this.state.baseUrl}/invoice/${this.props.params.id}`,
        body,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      let data = response.data;

      if (data.status === "Success") {
        // if (response.data.status === 'Success') {
          mixpanel.track('Approve Invoice', { email });
          mixpanel.people.set({ '$email': response.data.data.email });

        // }
        console.log(data.status);
        let inv = this.state.invoice;
        inv.status = body.status;
        this.setState({
          invoice: inv,
        });
        this.updateInvoice(inv);
        console.log(this.state.invoice);
      }
      console.log(data);
    } catch (e) {
      console.log(e);
    }
  };

  handleInputChange = async (event) => {
    this.state.branchIndex = event.branchIndex;
    console.log(this.state.branchIndex);
    const target = event.target;
    console.log("target", event);
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const comments = target.comments;
    let invoice = this.state.invoice;
    invoice[[name]] = value;
    invoice[[comments]] = target.comments;

    let nameLabel = (invoice["inv_type"] === 1 || invoice["inv_type"] === '1') ? "Seller" : "Buyer";
    let cred_debt_type = "";
    if (invoice["inv_type"] > 0) {
      cred_debt_type = (invoice["inv_type"] === 1 || invoice["inv_type"] === '1') ? "debtor" : "creditor";
    }
    let gstLabel = (invoice["inv_type"] === 2 || invoice["inv_type"] === '2') ? "Seller GST" : "Customer GST";


    await this.setState({
      invoice: invoice,
      CustomerLabel: nameLabel,
      CustomerGstLabel: gstLabel,
      cred_debt_type: cred_debt_type,
      isClientGstChange: (name=="seller_gst")? true: false,
      isCounterGstChange: (name=="counter_party_gst")? true: false, 
    });
    console.log(invoice["inv_type"] + nameLabel)
    console.log("handle input ", this.state.invoice);
  };

  handleProductChange = async (event) => {
    const target = event.target;
    const index = event.target.getAttribute("data-index");
    const value = target.type === "checkbox" ? target.checked : target.value;

    const name = target.name;
    let invoice = this.state.invoice;
    invoice.product_table[index][[name]] = value;
    console.log(invoice.product_table);
    await this.setState({
      invoice: invoice,
    });
    console.log(invoice);
  };
  handleInputTaxChange = async (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let invoice = this.state.invoice;
    invoice.taxes[0][[name]] = value;
    await this.setState({
      invoice: invoice,
    });
    console.log(invoice);
  };

  handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
        headers: { Accept: "*/*", "Access-Control-Allow-Origin": "*" },
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };
  // handleBack = () => {
  //   const history = useHistory();
  //   history.goBack();
  // };
  nextInvoice = async () => {
    this.checkImageExtension();
    const index = this.state.invoices.findIndex(
      (item) => item._id === this.state.invoice._id
    );
    console.log(index);
    console.log(this.state.invoices.length);
    if (index >= 0 && index + 1 < this.state.invoices.length) {
      const data = this.state.invoices[index + 1];
      let date = formateDate(data.inv_date);
      this.props.history.push(`/invoice/${data._id}`);
      this.setState({
        loader: true,
        invoice: data,
        invDate: date,
        company: data.client_company_id,
        currentInvoice: index + 1,
      });
    }
  };

  prevInvoice = async () => {
    this.checkImageExtension();
    const index = this.state.invoices.findIndex(
      (item) => item._id === this.state.invoice._id
    );
    console.log(index - 1);
    console.log(this.state.invoices.length);
    if (index > 0) {
      const data = this.state.invoices[index - 1];
      let date = formateDate(data.inv_date);
      this.props.history.push(`/invoice/${data._id}`);
      this.setState({
        loader: true,
        invoice: data,
        invDate: date,
        company: data.client_company_id,
        currentInvoice: index - 1,
      });
    }
  };

  checkImageExtension = (url) => {
    let imageUrl = this.state.invoice.doc_url;
    console.log("url extention function call", imageUrl);
    let image = imageUrl.split(/[#?]/)[0].split(".").pop().trim();
    console.log(image);
  };

  handleBack = () => {
    this.props.history.goBack();
  }

  hadleZoomInZoomOut = (zoom) => {
    console.log(this.refs.refImage.width);
    if (zoom) {
      this.setState({
        scale: this.state.scale * 1.1,
        width: this.state.width * 0.9,
        height: this.state.height * 0.9,
      });
    } else {
      this.setState({
        scale: this.state.scale * 0.9,
        width: this.state.width * 1.1,
        height: this.state.height * 1.1,
      });
    }

  };
  handleSwap = () => {
    // Swap the data between Company Side and Customer Side
    const {
      invoice,
      defaultComapny,
      counter_party,
      // CustomerLabel,
    } = this.state;

    const swappedInvoice = {
      seller_gst: invoice.counter_party_gst,
      counter_party_gst: invoice.seller_gst,
      client_company_address: invoice.counter_party_address,
      counter_party_address: invoice.client_company_address,
      client_company_state: invoice.counter_party_state,
      counter_party_state: invoice.client_company_state,
      client_company: invoice.counter_party,
      counter_party: invoice.client_company
    };

    const swappedCounterParty = counter_party;
    const swappedDefaultCompany = defaultComapny;

    this.setState({
      invoice: {
        ...invoice,
        ...swappedInvoice,
      },
      counter_party: swappedCounterParty,
      defaultComapny: swappedDefaultCompany,
      // CustomerLabel: CustomerLabel === 'Customer' ? 'Company' : 'Customer',
    });
  }


  gstDetailsUpdateHandler = async (gst, is_counter = false) =>{
    console.log(gst);
    if (isGSTNo(gst)) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/gst`,
          { gst: gst },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          let _invoice = this.state.invoice
          if (response.data.status === "Success") {
            const companyInfo =
              response.data.data.lstAppSCommonSearchTPResponse[0];
            console.log(companyInfo);
            const companyAddress = companyInfo["pradr"].addr;
             let party_name = companyInfo.tradeNam;
             let  state = companyAddress.stcd;
             let  city = companyAddress.loc;
             let  address = `${companyAddress.bno ?? ""} ${companyAddress.bnm ?? ""
                } ${companyAddress.flno ?? ""} ${companyAddress.lg ?? ""} ${companyAddress.lt ?? ""
                } ${companyAddress.st ?? ""} ${companyAddress.stcd+"-" ?? ""}${companyAddress.pncd ?? ""} `;
            
            if(is_counter){
              _invoice.counter_party =  party_name;
              _invoice.counter_party_address = address;
              _invoice.counter_party_state = state;
            }else{
              _invoice.client_company = party_name;
              _invoice.client_company_address = address;
              _invoice.client_company_state = state;
            }

            this.setState(
              { 
                invoice:_invoice,
                isClientGstChange: false,
                isCounterGstChange: false
              })
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }else{
      alert('Invalid GST number. Please enter valid value.')
    }
  };



  render() {
    const {
      showAddCompanyModal
    } = this.state;

    console.log("this is state" + this.state.company)
    // console.log(this.state.company)
    return (
      <>
        <div className="" style={{ minWidth: "1847px" }}>

          {showAddCompanyModal && (
            <AddCompanyModal
              show={showAddCompanyModal}
              companyId={this.state.company}
              setShowAddCompanyModal={(value) =>
                this.setState({ showAddCompanyModal: value })
              }
              setDisplayAlert={(value) => this.setState({ displayAlert: value })}
            />
          )}

          <Breadcrumb paths={[{ label: this.state.defaultComapny, active: true }, { label: "Invoice", active: true }]} ></Breadcrumb>

          <div className="row mt-3">
            <h1 style={{
              fontSize: "26px",
              color: "#475569",
              fontWeight: "700"
            }}>
              <button className="btn justify-content-left"
                onClick={this.handleBack}
              >
                {"<"}
              </button>
              Invoice
            </h1>
            <div className="col-6">


              {this.state.invoice.doc_url ? (

                <div
                  className=""
                  style={{
                    minWidth: "100%",
                    display: "inline-block",
                    position: "relative",
                    overflow: "auto",
                  }}
                >
                  <iframe
                    title="invoice"
                    src={this.state.invoice.doc_url}
                    style={{
                      minWidth: "100%",
                      maxHeight: 800,
                      WebkitTransform: `scale(${this.state.scale})`,
                      transform: `scale(${this.state.scale})`,
                      WebkitTransformOrigin: "0 0",
                      transformOrigin: "0 0",
                      height: 800,
                      overflow: "inherit",
                    }}
                    ref="refImage"
                  ></iframe>
                </div>

              ) :
                this.state.loader === true ? (
                  <div class="loader-wrapper">
                    <div class="loader"></div>
                  </div>
                ) : (
                  ""
                )}

            </div>
            <div className="col-6">
              <div className="w-100">
                <div className="d-flex gap-2">
                  <div className="w-50 mb-3 me-2 d-none">
                    <label className="form-label" htmlFor="split-login-email">
                      Select Invoice Type
                    </label>
                    <select
                      className="form-select"
                      name="inv_type"
                      value={this.state.invoice.inv_type}
                      onChange={this.handleInputChange}
                      aria-label="State"
                    >
                      <option key="0" value='0'>
                        Select type
                      </option>
                      <option key="2" value='2' >
                        Sale
                      </option>
                      <option key="1" value='1'>
                        Purchase
                      </option>
                    </select>
                  </div>
                  <div className="w-50 mb-3">
                    <label className="form-label" htmlFor="split-login-email">
                      Invoice Date
                    </label>
                    <input
                      className="form-control"
                      id="split-login-email"
                      type="date"
                      name="inv_date"
                      onChange={this.handleInputChange}
                      value={this.state.invoice.inv_date}
                    />
                  </div>



                  {/* <div className="w-100 d-flex gap-2"> */}
                  <div className="w-50">
                    <label className="form-label" htmlFor="split-login-email">
                      Invoice No.
                    </label>
                    <input
                      className="form-control"
                      id="split-login-password"
                      name="inv_no"
                      onChange={this.handleInputChange}
                      value={this.state.invoice.inv_no}
                      type="text"
                    />
                  </div>

                  {/* </div> */}
                </div>
                <div className="d-flex">
                  <div className="box">
                  <div className="d-flex justify-content-space-between w-100">
                    <label className="form-label" htmlFor="split-login-password">
                      GSTIN/UIN
                    </label>
                    {(this.state.isClientGstChange)?
                      <div
                            className="p ms-10 text-primary f-14 text-end"
                            type="button"
                            onClick={() => {this.gstDetailsUpdateHandler(this.state.invoice.seller_gst)}}
                      >
                            Update
                      </div>:""
                    }
                    </div>
                    <input
                      className="form-control d-block"
                      id="split-login-email"
                      name="seller_gst"
                      type="text"
                      onChange={this.handleInputChange}
                      value={this.state.invoice.seller_gst}
                    // disabled={true}
                    />
                    <div className="d-flex justify-content-space-between">
                      <label className="form-label me-4" htmlFor="split-login-password">
                        Seller Name
                      </label>

                    </div>

                    {/* {this.state.defaultComapny ? ( */}
                    <input

                      className="form-control d-block"
                      // onInputChange={(opt) => this.getAllClients(opt)}
                      onChange={this.handleInputChange}
                      aria-label="State"
                      labelKey="name"
                      name="client_company"
                      value={this.state.invoice.client_company}
                    // options={this.state.selectOptions}
                    // defaultValue={{
                    //   value: this.state.company,
                    //   label: this.state.defaultComapny,
                    // }}
                    />
                    {/* // ) : ( */}
                    {/* // "" */}
                    {/* // )} */}
                    {/* {!this.state.defaultComapny ? (
                      <input
                        className="form-control d-block"
                        // onInputChange={(opt) => this.getAllClients(opt)}
                        // onChange={this.handleInputChangeForClient}
                        aria-label="State"
                        labelKey="name"
                        value={this.state.value}
                        options={this.state.selectOptions}

                      />
                    ) : (
                      ""
                    )} */}
                    <br />
                    <label className="form-label" htmlFor="split-login-password">
                      Seller Address
                    </label>
                    <input
                      className="form-control"
                      onChange={this.handleInputChange}
                      id="client_company_address"
                      type="text"
                      name="client_company_address"
                      value={this.state.invoice.client_company_address}
                    />
                    <label className="form-label" htmlFor="split-login-password">
                      State</label>
                    <input
                      className="form-control d-block"
                      id="state-email"
                      name="client_company_state"
                      type="text"
                      value={this.state.invoice.client_company_state}
                    // value={this.invoice}
                    // disabled={true}
                    />
                  </div>
                  <div style={{ order: 1, margin: "auto 0px" }}>
                    <button className="px-2 bg-white text-black border-0" onClick={this.handleSwap}>
                      <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style={{ width: "1em", height: "1em", verticalAlign: "middle", fill: "currentColor", overflow: "hidden" }} viewBox="0 0 1024 1024" version="1.1"><path d="M922.048 593.536H102.016c-3.072 0-6.08 0-7.616 1.664h-3.008c-1.536 0-3.072 1.6-4.544 1.6-1.6 1.792-1.6 1.792-3.072 1.792-1.536 1.6-3.008 1.6-3.008 1.6-3.072 1.664-4.608 3.328-6.144 4.992-1.472 1.664-3.008 4.992-4.544 6.656 0 0 0 1.664-1.472 3.328 0 1.664-1.472 1.664-1.472 3.328s0 3.392-1.472 4.992v3.328c-1.664 3.264-1.664 9.92-0.128 14.848v3.328c1.472 1.664 1.472 3.392 1.472 4.992 1.472 1.664 1.472 1.664 1.472 3.328 1.472 1.664 1.472 3.328 1.472 3.328 1.536 3.328 3.072 4.992 4.544 6.656l261.248 285.504a37.12 37.12 0 0 0 53.12 0 42.816 42.816 0 0 0 0-58.112l-197.44-215.744h730.496c21.248 0 37.952-16.64 37.952-39.872s-16.576-41.536-37.824-41.536zM102.016 430.848h827.712c1.472 0 1.472-1.664 3.008-1.664s1.472 0 3.072-1.6c1.472 0 1.472-1.664 3.008-1.664 1.536-1.6 3.072-1.6 3.072-1.6 3.072-1.664 4.544-3.328 6.08-4.992s3.072-4.992 4.544-6.656c0 0 0-1.664 1.472-3.328 0-1.664 1.536-1.664 1.536-3.392 0-1.6 0-3.328 1.472-4.928v-3.328a25.728 25.728 0 0 0 0-14.912v-3.392c-1.472-1.664-1.472-3.328-1.472-4.928-1.536-1.664-1.536-1.664-1.536-3.328-1.472-1.664-1.472-3.328-1.472-3.328-1.472-3.392-3.072-4.992-4.544-6.656l-261.184-285.504c-7.744-8.32-16.768-11.648-25.92-11.648s-19.776 3.328-27.392 11.648a46.4 46.4 0 0 0 0 58.112l198.912 215.744H102.016c-21.248 0-38.016 18.304-38.016 39.872 0 23.232 16.768 41.472 38.016 41.472z" /></svg>
                    </button>
                  </div>
                  <div className="box">
                    {/* {this.state.invoices.map((item, index) => ( */}
                    <div className="d-flex justify-content-space-between w-100">
                    <label className="form-label" htmlFor="split-login-password">
                      GSTIN/UIN
                      </label>

                        {(this.state.isCounterGstChange)?
                        <div
                              className="p ms-10 text-primary f-14 text-end"
                              type="button"
                              onClick={() => {this.gstDetailsUpdateHandler(this.state.invoice.counter_party_gst, true)}}
                        >
                              Update
                        </div>:""
                      }
                    </div>
                    <input
                      className="form-control d-block"
                      id="split-login-email"
                      name="counter_party_gst"
                      type="text"
                      onChange={this.handleInputChange}
                      value={this.state.invoice.counter_party_gst}
                    // disabled={true}
                    />

                    <div className="d-flex justify-content-space-between">
                      <label className="form-label me-5" htmlFor="split-login-password">
                      Buyer Name
                      </label>
                      {/* {this.state.type === "" && !this.state?.company ? null : (
                        <span
                          className="p ms-10 clr-purple f-14 text-end"
                          type="button"
                          onClick={() => this.setState({ showAddCompanyModal: true })}
                        >
                          Add New
                        </span>
                      )} */}
                    </div>
                    {/* {this.state.defaultComapny ? ( */}
                    <input
                      className="form-control d-block"

                      // onInputChange={(opt) => this.getCreditorDebtors(opt)}
                      // onChange={this.handleInputChangeForCredDebt}
                      onChange={this.handleInputChange}
                      aria-label="State"
                      labelKey="name"
                      name="counter_party"
                      value={this.state.invoice.counter_party}
                    // options={this.state.selectCredDebt}
                    // defaultValue={{
                    // value: this.state.invoice.counter_party_id,
                    // label: this.state.invoice.counter_party,
                    // }}
                    />
                    {/* ) : ( */}
                    {/* "" */}
                    {/* )} */}
                    <br />
                    <label className="form-label" htmlFor="split-login-password">
                    Buyer Address
                    </label>
                    <input
                      className="form-control"
                      onChange={this.handleInputChange}
                      id="counter_party"
                      type="text"
                      name="counter_party_address"
                      value={this.state.invoice.counter_party_address}
                    />
                    <label className="form-label" htmlFor="split-login-password">
                      State</label>
                    <input
                      className="form-control d-block"
                      id="split-login-email"
                      name="buyer_gst"
                      type="text"
                      value={this.state.invoice.counter_party_state}
                    // disabled={true}
                    />
                  </div>
                </div>

                <div className="scrollbar w-100">
                  {this.state.invoice?.product_table?.map((item, index) => (
                    // index > 0 && !item.new && (
                    item.name || item.hsn || item.CGST || item.IGST || item.SGST || item.qty || item.total ? (

                      <div className="d-flex gap-2 mt-3 ms-auto" >
                        <div className="w-50 mb-3">
                          <label className="form-label" htmlFor="split-login-password">
                            Description of Goods
                          </label>
                          <textarea cols="25"
                            className="form-control text-area"
                            id="split-login-password"
                            type="text"
                            name="name"
                            onChange={this.handleProductChange}
                            value={item.name}
                            data-index={index}
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label" htmlFor="split-login-password">
                            HSN/SAC
                          </label>
                          <input
                            className="form-control"
                            id="split-login-password"
                            type="text"
                            name="hsn"
                            onChange={this.handleProductChange}
                            value={item.hsn}
                            data-index={index}
                          />
                        </div>

                        {(item?.CGST == null) ? null : (
                          <div className="mb-3">
                            <div className="d-flex justify-content-between">
                              <label
                                className="form-label"
                                htmlFor="split-login-password"
                              >
                                CGST
                              </label>
                            </div>
                            <input
                              className="form-control"
                              id="split-login-password"
                              type="number"
                              name="CGST"
                              onChange={this.handleProductChange}
                              value={item?.CGST}
                              data-index={index}
                            />
                          </div>
                        )}
                        {(item?.IGST == null && item?.tax == null) ? null : (
                          // !item.IGST && !item?.tax ? null : (
                          <div className="mb-3">
                            <div className="d-flex justify-content-between">
                              <label
                                className="form-label"
                                htmlFor="split-login-password"
                              >
                                IGST
                              </label>
                            </div>
                            <input
                              className="form-control"
                              id="split-login-password"
                              type="number"
                              name="IGST"
                              onChange={this.handleProductChange}
                              value={item.IGST}
                              data-index={index}
                            />
                          </div>
                        )}
                        {(item?.SGST == null) ? null : (
                          <div className="mb-3">
                            <div className="d-flex justify-content-between">
                              <label
                                className="form-label"
                                htmlFor="split-login-password"
                              >
                                SGST
                              </label>
                            </div>
                            <input
                              className="form-control"
                              id="split-login-password"
                              type="number"
                              name="SGST"
                              onChange={this.handleProductChange}
                              value={item?.SGST}
                              data-index={index}
                            />
                          </div>
                        )}
                        {/* {item.qty ? null :( */}
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label
                              className="form-label"
                              htmlFor="split-login-password"
                            >
                              Quantity
                            </label>
                          </div>
                          <input
                            className="form-control"
                            id="split-login-password"
                            type="text"
                            name="qty"
                            onChange={this.handleProductChange}
                            value={item.qty}
                            data-index={index}
                          />
                        </div>


                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label
                              className="form-label"
                              htmlFor="split-login-password"
                            >
                              Amount
                            </label>
                          </div>
                          <input
                            className="form-control"
                            id="split-login-password"
                            type="text"
                            name="subtotal"
                            onChange={this.handleProductChange}
                            value={item.total || item.subtotal}
                            data-index={index}
                          />
                        </div>

                        <div className="mb-3">
                          <div className="d-flex justify-content-between mt-3">
                            <div
                              onClick={() => this.deleteRow(index)}
                              className="border-0 bg-transparent">
                              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.19629 5.32178V22.3218H20.1963V5.32178H5.19629Z" stroke="#EF4444" stroke-width="2" stroke-linejoin="round" />
                                <path d="M10.6963 10.3218V16.8218" stroke="#EF4444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M14.6963 10.3218V16.8218" stroke="#EF4444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M2.69629 5.32178H22.6963" stroke="#EF4444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.69629 5.32178L10.3408 2.32178H15.0848L16.6963 5.32178H8.69629Z" stroke="#EF4444" stroke-width="2" stroke-linejoin="round" />
                              </svg>
                            </div>
                          </div>

                        </div>
                      </div>
                    ) : null)
                  )}
                </div>

                <button
                  className="btn d-flex goods mt-3 mb-3"
                  type="button"
                  name="submit"
                  status="1"
                  onClick={this.addRow}
                >
                  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.18677 1V17M17.1868 9L1.18677 9" stroke="#5945B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <span className="ps-2">Add Goods</span>
                </button>


                <div className="d-flex gap-2 mt-3 ms-auto" >
                  <div className="w-50 mb-3">

                    <h4 className="mt-4">TOTAL</h4>
                  </div>



                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label
                        className="form-label"
                        htmlFor="split-login-password"
                      >
                        Sub total
                      </label>
                    </div>
                    <input
                      className="form-control"
                      id="split-login-password"
                      type="number"
                      name="subtotal"
                      onChange={this.handleInputChange}
                      value={this.state.invoice.subtotal}

                    />
                  </div>
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label
                        className="form-label"
                        htmlFor="split-login-password"
                      >
                        IGST
                      </label>
                    </div>
                    <input
                      className="form-control"
                      id="split-login-password"
                      type="number"
                      name="IGST"
                      onChange={this.handleInputTaxChange}
                      value={
                        this.state.invoice.taxes
                          ? this.state.invoice.taxes[0].IGST
                          : 0
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label
                        className="form-label"
                        htmlFor="split-login-password"
                      >
                        SGST
                      </label>
                    </div>
                    <input
                      className="form-control"
                      id="split-login-password"
                      type="number"
                      name="SGST"
                      onChange={this.handleInputTaxChange}
                      value={
                        this.state.invoice.taxes
                          ? this.state.invoice.taxes[0].SGST
                          : 0
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label
                        className="form-label"
                        htmlFor="split-login-password"
                      >
                        CGST
                      </label>
                    </div>
                    <input
                      className="form-control"
                      id="split-login-password"
                      type="number"
                      name="CGST"
                      onChange={this.handleInputTaxChange}
                      value={
                        this.state.invoice.taxes
                          ? this.state.invoice.taxes[0].CGST
                          : 0
                      }
                    />
                  </div>

                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label
                        className="form-label"
                        htmlFor="split-login-password"
                      >
                        Amount
                      </label>
                    </div>
                    <input
                      className="form-control"
                      id="split-login-password"
                      type="number"
                      name="grand_total"
                      onChange={this.handleInputChange}
                      value={
                        this.state.invoice.grand_total
                      }
                    />

                  </div>

                </div>
                <div className="d-flex gap-2">
                  <div className="w-100 mb-3">
                    <div className="d-flex justify-content-between">
                      <label className="form-label">Comments*</label>
                    </div>
                    <input
                      className="comments w-100"
                      id="split-login-password"
                      type="text"
                      name="comments"
                      disabled={this.state.invoice.status !== 0 ? true : false}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>

                <div
                  className={`${this.state.invoice.status !== 0 ? "" : "d-none"}`}
                >
                  {/* <span className="alert">
                    Invoice is
                    {this.state.invoice.status === 1 ? " Approved" : " UnApproved"}
                  </span> */}
                </div>
                <div className={`d-flex gap-2 justify-content-space-between scrollbar w-100`}>
                  <div class="d-flex d-none">
                    <button type="button"
                      disabled={this.state.currentInvoice === 0}
                      className="btn btn-primary d-flex align-items-center gap-2 mx-3 zeconcile-btn purple"
                      onClick={this.prevInvoice}
                    >
                      <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.8735 4L6.87354 9L11.8735 14" stroke="#475569" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg> Prev

                    </button>
                    <button type="button"
                      disabled={
                        this.state.currentInvoice === this.state.invoices.length - 1
                      }
                      className="btn btn-primary d-flex align-items-center gap-2 zeconcile-btn purple"
                      onClick={this.nextInvoice}
                    >
                      Next <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.87354 14L11.8735 9L6.87354 4" stroke="#475569" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>

                    </button>
                  </div>
                  <div class="d-flex">
                    <button
                      onClick={this.updateInvoice}
                      className="btn btn-primary d-block gap-2 align-items-center mx-1 zeconcile-btn purple"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.40723 3.9375C2.40723 3.31618 2.91091 2.8125 3.53223 2.8125H13.0128L15.9072 5.51494V15.1875C15.9072 15.8088 15.4036 16.3125 14.7822 16.3125H3.53223C2.91091 16.3125 2.40723 15.8088 2.40723 15.1875V3.9375Z" stroke="#5945B3" stroke-width="1.5" stroke-linejoin="round" />
                        <path d="M9.16034 2.8125L9.15723 5.58172C9.15723 5.70919 8.98934 5.8125 8.78223 5.8125H5.78223C5.57511 5.8125 5.40723 5.70919 5.40723 5.58172V2.8125H9.16034Z" stroke="#5945B3" stroke-width="1.5" stroke-linejoin="round" />
                        <path d="M3.53223 2.8125H13.0128" stroke="#5945B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M5.40723 10.3125H12.9072" stroke="#5945B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M5.40723 13.3125H9.16034" stroke="#5945B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      <span className="ms-1"> Save Draft</span>
                    </button>
                    <button
                      onClick={this.getAllInvoices}
                      className="btn btn-primary d-block gap-2 mx-3 zeconcile-btn purple"
                      href={this.state.invoice.doc_url}
                      target="_blank"
                      rel="noreferrer"
                      download
                      alt=""
                    >
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.04639 7.73438L8.99951 10.6875L11.9526 7.73438" stroke="#5945B3" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8.99951 2.8103V10.6853" stroke="#5945B3" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15.7495 9.5625V14.625C15.7495 14.7742 15.6902 14.9173 15.5848 15.0227C15.4793 15.1282 15.3362 15.1875 15.187 15.1875H2.81201C2.66283 15.1875 2.51975 15.1282 2.41426 15.0227C2.30878 14.9173 2.24951 14.7742 2.24951 14.625V9.5625" stroke="#5945B3" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      <span className="ms-1">Download</span>
                    </button>
                    {(this.state.invoice.status == 0 || this.state.invoice.status == 2) ?
                      <button
                        className="btn btn-success d-flex gap-2 approve align-items-center  "
                        type="button"
                        name="submit"
                        status="1"
                        // disabled={this.state.invoice.status !== 0 ? true : false}
                        onClick={this.updateStatus}
                      >
                        <svg status="1" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.7743 5.59231C13.7082 5.52562 13.6295 5.47268 13.5428 5.43656C13.4561 5.40043 13.3631 5.38184 13.2691 5.38184C13.1752 5.38184 13.0822 5.40043 12.9955 5.43656C12.9088 5.47268 12.8301 5.52562 12.7639 5.59231L7.46291 10.9005L5.23576 8.66623C5.16709 8.59989 5.08601 8.54772 4.99717 8.51271C4.90833 8.4777 4.81346 8.46053 4.71799 8.46218C4.62251 8.46383 4.5283 8.48427 4.44072 8.52234C4.35314 8.5604 4.27392 8.61534 4.20758 8.68402C4.14123 8.7527 4.08907 8.83378 4.05406 8.92262C4.01904 9.01146 4.00187 9.10633 4.00353 9.2018C4.00518 9.29728 4.02562 9.3915 4.06368 9.47907C4.10175 9.56665 4.15669 9.64587 4.22537 9.71222L6.95771 12.4446C7.02386 12.5113 7.10256 12.5642 7.18927 12.6003C7.27598 12.6365 7.36898 12.6551 7.46291 12.6551C7.55684 12.6551 7.64985 12.6365 7.73656 12.6003C7.82327 12.5642 7.90196 12.5113 7.96811 12.4446L13.7743 6.6383C13.8466 6.57167 13.9042 6.4908 13.9436 6.40079C13.9831 6.31078 14.0034 6.21357 14.0034 6.11531C14.0034 6.01704 13.9831 5.91984 13.9436 5.82983C13.9042 5.73981 13.8466 5.65894 13.7743 5.59231Z" fill="white" />
                        </svg>
                        <span status="1"> Approve
                        </span>

                      </button>
                      : ""
                    }
                    {(this.state.invoice.status == 1) ?
                      < button
                        className="btn btn-danger d-flex gap-2 approve align-items-center  "
                        type="button"
                        name="submit"
                        status="0"
                        // disabled={this.state.invoice.status === 1 ? true : false}
                        onClick={this.updateStatus}
                      >
                        <svg status="0" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.7743 5.59231C13.7082 5.52562 13.6295 5.47268 13.5428 5.43656C13.4561 5.40043 13.3631 5.38184 13.2691 5.38184C13.1752 5.38184 13.0822 5.40043 12.9955 5.43656C12.9088 5.47268 12.8301 5.52562 12.7639 5.59231L7.46291 10.9005L5.23576 8.66623C5.16709 8.59989 5.08601 8.54772 4.99717 8.51271C4.90833 8.4777 4.81346 8.46053 4.71799 8.46218C4.62251 8.46383 4.5283 8.48427 4.44072 8.52234C4.35314 8.5604 4.27392 8.61534 4.20758 8.68402C4.14123 8.7527 4.08907 8.83378 4.05406 8.92262C4.01904 9.01146 4.00187 9.10633 4.00353 9.2018C4.00518 9.29728 4.02562 9.3915 4.06368 9.47907C4.10175 9.56665 4.15669 9.64587 4.22537 9.71222L6.95771 12.4446C7.02386 12.5113 7.10256 12.5642 7.18927 12.6003C7.27598 12.6365 7.36898 12.6551 7.46291 12.6551C7.55684 12.6551 7.64985 12.6365 7.73656 12.6003C7.82327 12.5642 7.90196 12.5113 7.96811 12.4446L13.7743 6.6383C13.8466 6.57167 13.9042 6.4908 13.9436 6.40079C13.9831 6.31078 14.0034 6.21357 14.0034 6.11531C14.0034 6.01704 13.9831 5.91984 13.9436 5.82983C13.9042 5.73981 13.8466 5.65894 13.7743 5.59231Z" fill="white" />
                        </svg>
                        <span status="0"> Unapprove
                        </span>

                      </button>
                      : ""
                    }
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div >
      </>
    );
  }
}

export default navigationHook(InvoicesDetail);
