import axios from "axios";

export const getUserCount =async (g) => {
    let result = {status:"Failure"};
    await axios.get(`${process.env.REACT_APP_BASE_URL}/org-user/info/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }).then((response) => {
          result = response.data;
         console.log(response.data);
         return response.data;
      }).catch(error => {
        console.error(error);
        return {status:"Failure"}
      });
      return result;
  };