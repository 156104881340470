import axios from "axios";
import React, { useEffect, useState } from "react";
import { isGSTNo } from "../../../common/Utils";
import Alert from "../../../common/alert/alert";

const AddBusinessUnitModal = (props) => {
  const [branch, setBranch] = useState({
    name: "",
    email: "",
    gst: "",
    address: "",
    point_of_contact: "",
    mobile: "",
    state: "",
    city: "",
    // pan_no: "",
    type: "null",
    company_id:props.companyId,
  });
  const [notify, setMessage] = useState({
    display: false,
    status: false,
    message: "",
  }); 
  console.log( props);
  useEffect(() => {
    if (isGSTNo(props.gst)) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/gst`,
          { gst: props.gst },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "Success") {
            const companyInfo =
              response.data.data.lstAppSCommonSearchTPResponse[0];
            console.log(companyInfo);
            const companyAddress = companyInfo["pradr"].addr;
            setBranch({
              ...branch,
              branch_name: companyInfo.tradeNam,
              state: companyAddress.stcd,
              city: companyAddress.loc,
              address: `${companyAddress.bno ?? ""} ${companyAddress.bnm ?? ""
                } ${companyAddress.flno ?? ""} ${companyAddress.lg ?? ""} ${companyAddress.lt ?? ""
                } ${companyAddress.st ?? ""} ${companyAddress.pncd ?? ""} `,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const onChangeHandler = (name, value) => {
    setBranch({ ...branch, [name]: value });
  };

  const submitBusinessUnitHandler = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/company/create-cred-debt`,
          branch
        ,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      console.log("Debt-Credtor Added in Database");
      props.clear();
      props.setShowAddCompanyModal(false);
      props.setDisplayAlert({
        display: true,
        status: true,
        message: "Company And Branch Added Successfully",
      });
    } catch (err) {
      console.log(err);
      // props.setShowAddCompanyModal(false);
      setMessage({
        display: true,
        status: false,
        message: err.response.data.message,
      });
    }
  };

  return (
    <div
      className="modal fade show"
      id="error-modal"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      // style={{ maxWidth: "500px" }}
      >
        <form
          className="modal-content position-relative"
          onSubmit={(e) => submitBusinessUnitHandler(e)}
        >
          <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
            <button
              className="clr-purple transparent btn btn-sm mt-2 me-2 d-flex flex-center "
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => props.setShowAddCompanyModal(false)}
            >
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.2752 12.1249L19.2871 7.12488C19.5065 6.90541 19.6298 6.60774 19.6298 6.29736C19.6298 5.98698 19.5065 5.68931 19.2871 5.46984C19.0676 5.25037 18.7699 5.12708 18.4595 5.12708C18.1492 5.12708 17.8515 5.25037 17.632 5.46984L12.6318 10.4816L7.63167 5.46984C7.41219 5.25037 7.11452 5.12708 6.80413 5.12708C6.49375 5.12708 6.19608 5.25037 5.9766 5.46984C5.75713 5.68931 5.63383 5.98698 5.63383 6.29736C5.63383 6.60774 5.75713 6.90541 5.9766 7.12488L10.9884 12.1249L5.9766 17.125C5.86736 17.2334 5.78065 17.3623 5.72147 17.5043C5.6623 17.6463 5.63184 17.7987 5.63184 17.9525C5.63184 18.1064 5.6623 18.2587 5.72147 18.4008C5.78065 18.5428 5.86736 18.6717 5.9766 18.78C6.08495 18.8893 6.21386 18.976 6.35589 19.0352C6.49793 19.0943 6.65027 19.1248 6.80413 19.1248C6.958 19.1248 7.11034 19.0943 7.25237 19.0352C7.39441 18.976 7.52332 18.8893 7.63167 18.78L12.6318 13.7683L17.632 18.78C17.7404 18.8893 17.8693 18.976 18.0113 19.0352C18.1533 19.0943 18.3057 19.1248 18.4595 19.1248C18.6134 19.1248 18.7657 19.0943 18.9078 19.0352C19.0498 18.976 19.1787 18.8893 19.2871 18.78C19.3963 18.6717 19.483 18.5428 19.5422 18.4008C19.6014 18.2587 19.6318 18.1064 19.6318 17.9525C19.6318 17.7987 19.6014 17.6463 19.5422 17.5043C19.483 17.3623 19.3963 17.2334 19.2871 17.125L14.2752 12.1249Z" fill="#5945B3" />
              </svg>

            </button>
          </div>
          <div className="modal-body p-0">
            <div className="rounded-top-lg py-3 ps-4 pe-6 ">
              <h4 className="mb-1" id="modalExampleDemoLabel">
                {branch.name}
              </h4>
            </div>
            {(notify.message)?  
            <Alert status={notify.status} setDisplayAlert={setMessage}>
                {notify.message}
            </Alert>:""
            }

            <div className="p-4 pb-0">
              <div className="row">
                <div className="mb-3 w-50">
                  <label className="col-form-label">Customer Name</label>
                  <input
                    className="form-control"
                    type="text"
                    onChange={(e) =>
                      onChangeHandler("name", e.target.value)
                    }
                    value={branch.name}
                  />
                </div>
                <div className="mb-3 w-50">
                  <label className="col-form-label">Email</label>
                  <input
                    required
                    className="form-control"
                    type="text"
                    onChange={(e) => onChangeHandler("email", e.target.value)}
                    value={branch.email}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-3 w-50">
                  <label className="col-form-label">Customer type </label>
                  <select
                    required
                    className="form-control"
                    type="text"
                    onChange={(e) => onChangeHandler("type", e.target.value)}
                    value={branch.type}
                  >

                    <option value="">Select type</option>
                    <option value="creditor">Creditor</option>
                    <option value="debtor">Debtor</option>

                  </select>
                </div>
                <div className="mb-3 w-50">
                  <label className="col-form-label">GST Number </label>
                  <input
                    required
                    className="form-control"
                    type="text"
                    onChange={(e) => onChangeHandler("gst", e.target.value)}
                    value={branch.gst}

                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-3 w-50">
                  <label className="col-form-label">Address </label>
                  <input
                    required
                    className="form-control"
                    type="text"
                    onChange={(e) => onChangeHandler("address", e.target.value)}
                    value={branch.address}
                  />
                </div>

                <div className="mb-3 w-50">
                  <label className="col-form-label">City</label>
                  <input
                    required
                    className="form-control"
                    type="text"
                    onChange={(e) => onChangeHandler("city", e.target.value)}
                    value={branch.city}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-3 w-50">
                  <label className="col-form-label">State </label>
                  <input
                    required
                    className="form-control"
                    type="text"
                    onChange={(e) => onChangeHandler("state", e.target.value)}
                    value={branch.state}
                  />
                </div>
                <div className="mb-3 w-50">
                  <label className="col-form-label">Mobile</label>
                  <input
                    required
                    className="form-control"
                    type="text"
                    onChange={(e) => onChangeHandler("mobile", e.target.value)}
                    value={branch.mobile}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-3 w-50">
                  <label className="col-form-label">Point of Contact</label>
                  <input
                    required
                    className="form-control"
                    type="text"
                    onChange={(e) =>
                      onChangeHandler("point_of_contact", e.target.value)
                    }
                    value={branch.point_of_contact}
                  />
                </div>
              </div>

            </div>
          </div>
          <div className="modal-footer border-0 mx-auto">
            <button
              className="zeconcile-btn btn purple"
              type="button"
              data-bs-dismiss="modal"
              onClick={() => props.setStep(2)}
            >
              Back
            </button>
            <button className="zeconcile-btn btn" type="submit"
              onClick={(e) => submitBusinessUnitHandler(e)}
            >
              Add Customer
            </button>
          </div>
        </form >
      </div >
    </div >
  );
};

export default AddBusinessUnitModal;
