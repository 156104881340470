import React from "react";
import navigationHook from "../common/hooks";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { CSVLink } from "react-csv";
import "./companies/companies.css";
import Breadcrumb from "../common/breadcrums";
// import { Search } from "semantic-ui-react";


class Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseUrl: process.env.REACT_APP_BASE_URL, //"http://localhost:8100/api",
      invoices: [],
      page: 1,
      query: [],
      gst: false,
      Company: props.params.id,
      totalPage: 1,
      company_data: [],
      current_branch: null,
      loader: true,
      csvLoader: false,
      allInvoices: [],
      uniqueInvoices: [],
      isBranchAvailable: true,
      type: 0,
      isFilter: false,
      recentClients: [],
    };
    localStorage.removeItem("fileKey");
    // this.getInvoices();
    console.log(this.props);
  }
  csvLink = React.createRef();

  componentDidMount() {
    this.getCompany();

    // this.setState({
    //   type: 0,
    //   isFilter: false,
    //   isStatusFilter: false,
    //   status: 0
    // })
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log("GOT ERROR");
    console.log(error);
    return { hasError: true };
  }

  // componentDidCatch(error, errorInfo) {
  //     // You can also log the error to an error reporting service
  //     console.log(error, errorInfo);
  // }
  getCompany = async () => {
    try {
      this.setState({
        // invoices:[],
        loader: true,
        page: 1,
      });
      if (this.state.Company !== "null") {
        console.log(this.state.Company);
        console.log("get company");

        let response = await axios.get(
          `${this.state.baseUrl}/company/` + this.state.Company,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        let data = response.data.data;
        console.log(data);
        this.setState(
          {
            company_data: data,
            current_branch: data.branches[0]?._id,
          },
          () => {
            this.getInvoices();
          }
        );
      } else {
        this.getInvoices();
      }

      // this.getParseData();
    } catch (e) {
      console.log(e);
    }
  };

  getInvoices = async () => {
    console.log(this.state);
    try {
      console.log("called me");
      console.log(this.state.Company);
      let body = {
        query: {
          client_company_id:
            this.state.Company === "null" ? null : this.state.Company,
        },
      };
      if (this.state.Company !== null) {
        body = {
          query: {
            $and: [
              {
                client_company_id:
                  this.state.Company === "null" ? null : this.state.Company,
              },
              {
                company_branch_id:
                  this.state.Company !== "null"
                    ? this.state.current_branch
                    : null,
              },
            ],
          },
        };
      }
      if (this.state.isFilter) {
        body.query = {
          client_company_id: this.state.Company === "null" ? null : this.state.Company,
          company_branch_id: this.state.Company !== "null" ? this.state.current_branch : null,
          inv_type: this.state.type
        };
        console.log(body);
        if (this.state.status) { body.query.status = this.state.status; }

        let response = await axios.put(
          `${this.state.baseUrl}/invoice/company/` + this.state.page, body,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
          }
        );
        console.log(response.data);
        let data = response.data;
        this.setState(
          {
            uniqueInvoices: data.data,
            totalPage: data.totalPage,
            loader: false,
            isBranchAvailable: true,
            isFilter: true,
            invoices: data.data
          },
          () => {
            this.pagination();
          }
        );
      } else {
        if (this.state.isStatusFilter) {
          body.query.status = this.state.status
        }

        let response = await axios.put(
          `${this.state.baseUrl}/invoice/company/` + this.state.page,
          body,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        let data = response.data.data;
        console.log(data);
        this.setState(
          {
            invoices: data,
            uniqueInvoices: [],
            isFilter: false,
            totalPage: response.data.totalPage,
            loader: false,
            isBranchAvailable: true,
          },
          () => {
            this.pagination();
          }
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
  getSearchInvoice = (opt) => {
    console.log(opt);

    if (opt) {
      const axios = require('axios');
      let data = JSON.stringify({
        "search": opt,
        client_company_id: this.state.Company,

        // "client_company_id": this.state.company
      });

      let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `https://zeconcile.thesecondwheel.in/api/invoice/search/` + this.state.page,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          let data = response.data;
          console.log(data);

          this.setState(
            {
              uniqueInvoices: data.data,
              totalPage: data.totalPage,
              loader: false,
              isBranchAvailable: true,
              isFilter: true,
              invoices: data.data
            },
            () => {
              this.pagination();
            }
          );
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });

      // const body = {
      //   client_company_id: this.state.Company,
      //   search: "pra"
      // };

      // axios
      //   .put(`${this.state.baseUrl}/invoice/search/` + this.state.page, body,

      //     {
      //       headers: {
      //         Authorization: `Bearer ${localStorage.getItem("token")}`,
      //       },
      //     })
      //   .then((response) => {
      //     const { data } = response;

      //     // let data = response.data.data;
      //     console.log("Invoice", data);
      //     // setCompanies(data);

      //     // setCompanies({
      //     //   companies: data.data,
      //     //   totalPage: data.totalPage,
      //     //   loader: false,
      //     // });
      //   })

      //   .catch((e) => {
      //     console.log(e);
      //     // console.log(data);
      //   })
      //   .finally((f) => { });
    } else {
      // getCompanies();
      console.log("loading");
    }
  };

  handleSearchSubmit = (event) => {
    event.preventDefault();
    console.log(event);
    const searchTerm = event.target.value;
    this.getSearchInvoice(searchTerm);
  };
  getAllInvoices = async (e) => {
    e.preventDefault();
    if (!this.state.csvLoader) {
      this.setState({ csvLoader: true });
      axios
        .get(`${this.state.baseUrl}/invoice-all/` + this.state.Company, null, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          let data = response.data.data;
          console.log(response.data);
          var allFilterInvoices = data.map((item) => ({
            // "Invoice received date": `${new Date(
            //   item.createdAt
            // ).toLocaleTimeString()} ${new Date(
            //   item.createdAt
            // ).toLocaleDateString()}`,
            Particulars: item.counter_party,
            "GSTIN/UIN": (item.buyer_gst) ? item.buyer_gst : item.counter_party_gst,
            "Vch Type":
              item.inv_type === 0
                ? "UNCATEGORIZED"
                : item.inv_type === 1
                  ? "PURCHASE"
                  : "SALE",
            Invoice: item.inv_no,
            Taxable: item.subtotal,
            "Integrated Tax": item.taxes[0].IGST,
            "Central Tax": item.taxes[0].CGST,
            "State Tax": item.taxes[0].SGST,
            "Total Tax":
              item.taxes[0].IGST + item.taxes[0].CGST + item.taxes[0].SGST,
          }));
          this.setState(
            { allInvoices: allFilterInvoices, uniqueInvoices: [] },
            () => {
              console.log(this.state.allInvoices);
              this.forceUpdate();
              this.csvLink.current.link.click();
            }
          );
        })
        .catch((e) => {
          console.log(e);
        })
        .finally((f) => {
          this.setState({ csvLoader: false });
        });
    }
  };

  keyChange = async (e) => {
    let index = e.target.attributes.getNamedItem("data-key").value;
    console.log(index);
    localStorage.setItem("fileKey", index);
    this.getParseData();
  };

  pageChange = async (e) => {
    let p = e.target.attributes.getNamedItem("data-page").value;
    console.log(p);
    this.setState(
      {
        page: Number(p),
        loader: true,
        invoices: [],
      },
      () => {
        this.getInvoices();
      }
    );
  };

  handleBranchChange = async (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    await this.setState(
      {
        current_branch: value,
        loader: true,
        invoices: [],
      },
      () => {
        this.getInvoices();
      }
    );
    console.log(value);
  };

  componentWillReceiveProps(nextProps) {
    console.log("nextProps", nextProps);
    console.log(nextProps.params.id);
    this.setState(
      {
        Company: nextProps.params.id,
        invoices: [],
        company_data: [],
        isFilter: false,
      },
      () => {
        console.log("updated status ");
        this.getCompany();
      }
    );
  }

  dataFromListOfAllInvoices = () => {
    return this.state.allInvoices;
  };

  pagination = async () => {
    let menuItems = [];
    let pre = Number(this.state.page) - 1;
    let next = Number(this.state.page) + 1;

    if (this.state.totalPage <= 10) {
      menuItems = [];
      menuItems.push(
        <li className={`page-item ${pre <= 0 ? "disabled" : ""}`} key="pre">
          <span
            className="zeconcile-btn border-0 transparent "
            data-page={pre}
            onClick={pre <= 0 ? null : this.pageChange}
          >
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.8735 4L6.87354 9L11.8735 14" stroke="#475569" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
        </li>
      );
      for (var i = 0; i < this.state.totalPage; i++) {
        menuItems.push(
          <li
            key={i + 11}
            className={`page-item ${this.state.page === i + 1 ? "active" : ""}`}
          >
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={i + 1}
            >
              {i + 1}
            </span>
          </li>
        );
      }
    } else {
      menuItems = [];
      menuItems.push(
        <li className={`page-item ${pre <= 0 ? "disabled" : ""}`} key="pre">
          <span
            className="zeconcile-btn border-0 transparent"
            data-page={pre}
            onClick={pre <= 0 ? null : this.pageChange}
          >
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.8735 4L6.87354 9L11.8735 14" stroke="#475569" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
        </li>
      );
      if (this.state.page > 4 && this.state.page < this.state.totalPage - 2) {
        menuItems.push(
          <li
            key={"abd123"}
            className={`page-item ${this.state.page === 1 ? "active" : ""}`}
          >
            <span className="page-link" onClick={this.pageChange} data-page={1}>
              1
            </span>
          </li>,
          <li
            key={"abd124l"}
            className={`page-item ${this.state.page === 1 ? "active" : ""}`}
          >
            <span
              className="page-link"
              // onClick={this.pageChange}
              data-page="..."
            >
              ...
            </span>
          </li>
        );

        menuItems.push(
          <li key={"abdl125"} className={`page-item`}>
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={this.state.page - 2}
            >
              {this.state.page - 2}
            </span>
          </li>
        );

        menuItems.push(
          <li key={"abd125"} className={`page-item`}>
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={this.state.page - 1}
            >
              {this.state.page - 1}
            </span>
          </li>,
          <li key={"abd126"} className={`page-item active`}>
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={this.state.page}
            >
              {this.state.page}
            </span>
          </li>,
          <li key={"abd127"} className={`page-item`}>
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={this.state.page + 1}
            >
              {this.state.page + 1}
            </span>
          </li>,
          <li key={"abd1281"} className={`page-item`}>
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={this.state.page + 2}
            >
              {this.state.page + 2}
            </span>
          </li>,
          <li
            key={"abd124"}
            className={`page-item ${this.state.page === 1 ? "active" : ""}`}
          >
            <span
              className="page-link"
              // onClick={this.pageChange}
              data-page="..."
            >
              ...
            </span>
          </li>,
          <li
            key={"abd128"}
            className={`page-item ${this.state.page === this.state.totalPage ? "active" : ""
              }`}
          >
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={this.state.totalPage}
            >
              {this.state.totalPage}
            </span>
          </li>
        );
      } else {
        for (var j = 0; j < 5; j++) {
          menuItems.push(
            <li
              key={j + 11}
              className={`page-item ${this.state.page === j + 1 ? "active" : ""
                }`}
            >
              <span
                className="page-link"
                onClick={this.pageChange}
                data-page={j + 1}
              >
                {j + 1}
              </span>
            </li>
          );
        }

        menuItems.push(
          <li key={"abd129"} className={`page-item`}>
            <span
              className="page-link"
              // onClick={this.pageChange}
              data-page="..."
            >
              ...
            </span>
          </li>,
          <li
            key={"abd1201a"}
            className={`page-item ${this.state.page === this.state.totalPage - 3 ? "active" : ""
              }`}
          >
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={this.state.totalPage - 3}
            >
              {this.state.totalPage - 3}
            </span>
          </li>,
          <li
            key={"abd1201"}
            className={`page-item ${this.state.page === this.state.totalPage - 3 ? "active" : ""
              }`}
          >
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={this.state.totalPage - 2}
            >
              {this.state.totalPage - 2}
            </span>
          </li>,
          <li
            key={"abd1202"}
            className={`page-item ${this.state.page === this.state.totalPage - 1 ? "active" : ""
              }`}
          >
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={this.state.totalPage - 1}
            >
              {this.state.totalPage - 1}
            </span>
          </li>,
          <li
            key={"abd1210"}
            className={`page-item ${this.state.page === this.state.totalPage ? "active" : ""
              }`}
          >
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={this.state.totalPage}
            >
              {this.state.totalPage}
            </span>
          </li>
        );
      }
    }
    menuItems.push(
      <li
        key="next"
        className={`page-item ${next > this.state.totalPage ? "disabled" : ""}`}
      >
        <span className="zeconcile-btn border-0 transparent" onClick={this.pageChange} data-page={next}>
          <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.87354 14L11.8735 9L6.87354 4" stroke="#475569" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </span>
      </li>
    );

    await this.setState({
      menuItems: menuItems,
    });
  };

  render() {
    // const title = this.state.type === 2 ? "Sales" : "Purchase";
    const title = this.state.type === 2 ? "Sales" : this.state.type === 1 ? "Purchase" : "Sales/Purchase";
    const status = this.state.status === 0 ? "Un-Approved" : this.state.status === 1 ? "Approved" : "Status";

    console.log(this.state);
    let menuItems = [];
    let pre = Number(this.state.page) - 1;
    let next = Number(this.state.page) + 1;
    menuItems.push(
      <li className={`page-item ${pre <= 0 ? "disabled" : ""}`} key="pre">
        <span
          className="page-link"
          data-page={pre}
          onClick={pre <= 0 ? null : this.pageChange}
        >
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1963 4.32178L7.19629 9.32178L12.1963 14.3218" stroke="#5945B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

        </span>
      </li>
    );
    for (var i = 0; i < this.state.totalPage; i++) {
      menuItems.push(
        <li
          key={i + 11}
          className={`page-item ${this.state.page === i + 1 ? "active" : ""}`}
        >
          <span
            className="page-link"
            onClick={this.pageChange}
            data-page={i + 1}
          >
            {i + 1}
          </span>
        </li>
      );
    }
    menuItems.push(
      <li
        key="next"
        className={`page-item ${next > this.state.totalPage ? "disabled" : ""}`}
      >
        <span className="zeconcile-btn border-0 transparent" onClick={this.pageChange} data-page={next}>
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.19629 14.3218L12.1963 9.32178L7.19629 4.32178" stroke="#5945B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

        </span>
      </li>
    );
    return (
      <>

        {/* <Breadcrumb paths={[{ label: "All Client", active: false },{ label: this.state.company_data.name, active: true }]} ></Breadcrumb> */}
        <div className="p-2">
          <Breadcrumb paths={[]} ></Breadcrumb>
        </div>
        <div className="row m-0 ms-2 my-3">
          <h4 className="mb-0 p-1 text-capitalize">
            {this.state.company_data.name
              ? this.state.company_data.name
              : "Invoices"}
          </h4>
          {/* <h4 className="text-primary fw-bold mb-0">Ze<span className="text-info fw-medium">Concile</span></h4> */}
        </div>
        <div className="col-12 my-3 d-flex px-2">
          <div className="col-3 d-flex zc-invoice-list-controller-search ps-0">
            {/* <form className="ms-2"> */}
            <input onChange={this.handleSearchSubmit} className="py-0 form-control" type="search" placeholder="Search" aria-label="Search" />

            {/* <button className="btn btn-outline-success" type="submit">Search</button> */}
            {/* </form> */}
          </div>

          <div className="filter-check-controller ms-2">

            <div className="dropdown">
              <button type="button" class="zeconcile-btn btn transparent2 clr-purple  dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                {title}
                {/* Sales/Purchase */}
              </button>
              <ul className="dropdown-menu">
                <li className="dropdown-item"><label className="label">
                  <input type="radio"
                    id="sale"
                    name="invoiceType"
                    value="Sales"
                    // checked={this.state.type === 'sales'}
                    onClick={() => {
                      this.setState(
                        { type: 2, page: 1, isFilter: true },
                        () => {
                          this.getInvoices();
                        }
                      );
                      console.log(this.handleOptionChange);
                      this.handleOptionChange();
                    }}
                    className="form-check-input mx-2"
                    onChange={() => {
                      this.setState(
                        { type: 2, page: 1, isFilter: true },
                        () => {
                          this.getInvoices();
                        }
                      );
                    }}
                  />

                  Sales</label></li>
                <li className="dropdown-item"><label className="label">
                  <input type="radio"
                    id="purchases"
                    name="invoiceType"
                    value="Purchase"
                    // checked={this.state.type === 'Purchase'}

                    onClick={() => {
                      this.setState(
                        { type: 2, page: 1, isFilter: true },
                        () => {
                          this.getInvoices();
                        }
                      );
                    }}
                    className="form-check-input mx-2"
                    onChange={() => {

                      this.setState(
                        { type: 1, page: 1, isFilter: true },
                        () => {
                          this.getInvoices();
                        }
                      );
                    }}
                  // checked={this.state.type === 1} // add a checked attribute to indicate the default option


                  />
                  Purchase</label></li>
                <li className="dropdown-item">
                  <label className="label">
                    <input type="radio"
                      id="Expenses"
                      name="invoiceType"
                      value="Expenses"
                      // checked={this.state.type === 'Expenses'}
                      onChange={this.handleOptionChange}
                      // onClick={() => {
                      //   this.setState(
                      //     { type: 2, page: 1, isFilter: true },
                      //     () => {
                      //       this.getInvoices();
                      //     }
                      //   );
                      // }}
                      className="form-check-input mx-2"

                    />
                    Expenses</label>

                </li>
                {/* <li className="dropdown-item">Separated link</li> */}
              </ul>
            </div>

            <div class="ms-2">
              <button type="button" class="zeconcile-btn btn transparent2 clr-purple dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                {status}
              </button>
              <ul class="dropdown-menu">
                <li className="dropdown-item">
                  <label className="label">
                    <input type="radio"
                      id="Expenses"
                      name="invoiceStatus"
                      value="Approved"
                      onClick={() => {
                        console.log("this.invoice is working");
                        this.setState(
                          { status: 1, page: 1, isStatusFilter: true },
                          () => {
                            this.getInvoices();
                          }
                        );
                      }}
                      className="form-check-input mx-2"

                    />Approved</label>
                </li>
                <li className="dropdown-item">
                  <label className="label">
                    <input type="radio"
                      id="Expenses"
                      name="invoiceStatus"
                      value="Unapproved"
                      onClick={() => {
                        this.setState(
                          { status: 0, page: 1, isStatusFilter: true },
                          () => {
                            this.getInvoices();
                            // console.log(this.getInvoices);
                          }
                        );
                      }}
                      className="form-check-input mx-2"

                    />
                    Un Approved</label>
                </li>

              </ul>
            </div>

            {/* old code  */}
          </div>

          <div className="col">
            {/* <div className="me-10">

            </div> */}
          </div>

          <div className="d-flex justify-content-end zc-invoice-list-controller pt-0">
            {/* </div>

          <div className="col-lg-4 d-flex justify-content-end"> */}
            <div className="zc-special-controller ">

              <button
                onClick={this.getAllInvoices}
                className="zeconcile-btn btn mx-2 purple {
                "
              >
                {this.state.csvLoader
                  ? "Loading csv..."
                  : "Download Invoices"}
              </button>
              <CSVLink
                data={this.state.allInvoices}
                ref={this.csvLink}
                filename={`${this.state.company_data.name} Invoices`}
              ></CSVLink>

              <NavLink className="text-white text-decoration-none zeconcile-btn" to={"/debt-cred/" + this.props.params.id}>
                <span>
                  View all Debtors & Creditors
                </span>
                <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="Icon/ 18px/ Arrow Right">
                    <path id="Vector" d="M7.19629 14.3218L12.1963 9.32178L7.19629 4.32178" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </g>
                </svg>
              </NavLink>

            </div>
          </div>
        </div>

        <div className="row g-3 m-0 mb-3 py-0">
          <div
            className={
              this.state.Company !== "null"
                ? "d-flex justify-content-end mt-1"
                : "d-flex justify-content-end mt-1 d-none"
            }
          >
            <select
              className={
                this.state.company_data?.branches?.length > 0
                  ? "d-none"
                  : "d-none"
              }
              name="current_branch"
              onChange={this.handleBranchChange}
              value={this.state.current_branch}
              aria-label="State"
            >
              {this.state.company_data.branches?.map((item, index) => (
                <option key={item._id} value={item._id}>
                  {item.branch_name}
                </option>
              ))}
            </select>
            <div
              className={
                this.state.company_data?.branches?.length > 0
                  ? "d-none"
                  : "w-25 d-none"
              }
              style={{ textAlign: "center" }}
            >
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
          <div className="scrollbar">
            <table className="zeconcile-table">
              <thead>
                <tr>
                  <th ><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                  </svg>
                    Party Name</th>
                  <th><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                  </svg>
                    Invoice Received Date</th>
                  <th><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                  </svg>
                    GSTIN/UIN</th>
                  <th><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                  </svg>
                    Sales/Purchase</th>
                  <th><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                  </svg>
                    Invoice</th>
                  <th><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                  </svg>
                    Taxable</th>
                  <th><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                  </svg>
                    Total GST</th>
                  <th><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                  </svg>
                    Status</th>
                  {/* <th scope="col">State Tax</th> */}
                  {/* <th scope="col">Total Tax</th> */}
                  {/* <th scope="col">Invoice Numbe</th> */}
                  {/* <th className="text-end" scope="col">Actions</th> */}
                </tr>
              </thead>

              <tbody>

                {this.state.invoices.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <NavLink to={"/invoice/" + item._id}>
                        {item.counter_party ? (
                          item.counter_party
                        ) : (
                          <p style={{ color: "red" }}>Details Missing</p>
                        )}
                      </NavLink>
                    </td>
                    <td>
                      {new Date(item.createdAt).toLocaleTimeString() +
                        " " +
                        new Date(item.createdAt).toLocaleDateString()}
                    </td>
                    <td>{(item.buyer_gst) ? item.buyer_gst : item.counter_party_gst}</td>
                    <td>
                      {item.inv_type === 0
                        ? "UNCATEGORIZED"
                        : item.inv_type === 1
                          ? "PURCHASE"
                          : "SALE"}
                    </td>
                    <td>{item.inv_no}</td>
                    <td>{item.subtotal}</td>
                    <td>{item.grand_total}</td>
                    <td>
                      {(item.status === 1 || item.status === '1') ?
                        <span class="zeconcile-chip success">
                          <span class="dot"></span>
                          Approved
                        </span>
                        : (item.status === 0 || item.status === '0') ?
                          <span class="zeconcile-chip danger">
                            <span class="dot"></span>
                            Un Approved
                          </span> :
                          <span class="zeconcile-chip danger">
                            <span class="dot"></span>
                            Rejected
                          </span>
                      }
                    </td>
                  </tr>
                ))}
                {this.state.invoices.length < 1 &&
                  this.state.loader === false ? (
                  <tr key="-3">
                    <td colSpan="7">No Invoice Found</td>
                  </tr>
                ) : this.state.loader === true ? (
                  <tr key="-2">
                    <td colSpan="7" style={{ textAlign: "center" }}>
                      <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>

              {
                this.state.totalPage > 1 ? (
                  <tfoot key="110">

                    <tr key="11001" className="p-2" >
                      <td colSpan="8" className="p-0 pt-3">
                        <nav aria-label="Page navigation example">
                          <ul className="pagination justify-content-end ms-2">
                            {this.state.menuItems}
                          </ul>
                        </nav>
                      </td>
                    </tr>
                  </tfoot>
                ) : (
                  ""
                )
              }
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default navigationHook(Company);