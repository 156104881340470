import React from "react";
import navigationHook from "../../common/hooks";
import axios from 'axios';
import Dropzone from 'react-dropzone';
import { Link } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { getUserCount } from "../../common/function";
// import 'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css';

// import { Redirect } from "react-router-dom";


class NewInvoice extends React.Component {
  mixpanel = () => {
    let email = localStorage.getItem('zc_userEmail');
    mixpanel.track('Invoice processed', { email });
  }
  logoutHandler = () => {
    localStorage.removeItem("token");
    window.location.reload(false);
  };

  constructor(props) {
    super(props);
    this.state = {
      baseUrl: process.env.REACT_APP_BASE_URL,
      selectedFile: null,
      phoneNumber: '',
      uploadedImages: [],
      imagesInQueue: [],
      reqData: "",
      isImageuploaded: false,
      isLoadingImages: false,
      listImages: [],
      maxFiles: 20,
      usedPage: 0,
      pageLimit: 20,
      pageLimitExisted: false,
      isCurrentUploads: 0
    };
  }

  handlePhoneNumberChange = (event) => {
    // Remove all non-numeric characters from the input
    const newPhoneNumber = event.target.value.replace(/\D/g, '');

    // Update the state with the new phone number
    this.setState({
      phoneNumber: newPhoneNumber
    });
  };

  updateParseCount = async ()=>{
    let data = await getUserCount();
    console.log(data);
    if(data.status == "Success"){
      this.setState({
        usedPage: data.data.total_page_parsed,
        pageLimit: data.data.max_allowed_parse_page,
        pageLimitExisted: (data.data.max_allowed_parse_page<=data.data.total_page_parsed)? true : false
      },()=>{
        this.handlePageLimit();
      })
    }
  }

  handleFileInputChange = (event) => {
    this.setState({
      selectedFile: event.target.files[0]
    });
  };
  handleFileUpload = async (files) => {
    mixpanel.init('c45b41f7cb0c1d55172b2375d52dfc5e');

    // TODO Show/Hide loader. 
    await this.setState({ isLoadingImages: true });

    console.log("URL " + this.state.baseUrl);
    const formData = new FormData();

    // Append all selected files to the form data
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    let response = await axios.post(`${this.state.baseUrl}/file/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    // .then(async (response) => {
    console.log(response.data);
    if (response.data.status === 'Success') {
      let email = localStorage.getItem('zc_userEmail');
      console.log(email);
      mixpanel.track('Invoice processed', { email });
      mixpanel.identify(response.data.data._Id);
      mixpanel.people.set({ '$email': response.data.data.email });
    }

    let img_list = this.state.listImages;
    if (response.data.data.length > 0) {
      img_list.push(response.data.data);
      console.log("pushed");
      await this.setState({
        listImages: img_list,
        isCurrentUploads: response.data.data.length
      }, async () => {
        console.log(this.state.listImages);
        let invoice = [];
        //  await Promise.all(
        response.data?.data?.map((fileData) => {
          let fileKey = fileData.location;
          console.log(fileKey);
          if (fileKey !== "") {
            // let rlo = await this.processDoc(
            invoice.push({ key: fileKey, location: fileData.location, mobile: this.state.phoneNumber });
            // setTimeout(() => {
            //   console.log(rlo);
            // }, 1000);
          }
        })
        // )
        await this.processDocMulti({ invoice: invoice });
      })
    }
    // Process each uploaded file
    // this.setState({ loading: false });

    // }).catch(error => {
    //   console.error(error);
    //   // this.setState({ loading: false });

    // });
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  dropzoneHandleNewFiles = async (files) => {
    const maxFiles = this.state.maxFiles;
    if (!files.length) {
      alert('No files selected');
      return;
    }
    if (files.length > maxFiles) {
      var message = "You can only upload a maximum of " + maxFiles + " files.";
      var divElement = document.getElementById("msg");
      divElement.innerText = message;
      divElement.className = "mx-auto alert alert-danger";
      // document.getElementById("msg").innerText = message;
      // alert(`You can only upload a maximum of ${maxFiles} files.`);
      return;
    }else if(files.length > (this.state.pageLimit-this.state.usedPage) ){
          var message = "You not allowed only more than "+ this.state.pageLimit + " Invoices. Please contact administrator to extend limit." ;
          var divElement = document.getElementById("msg");
          divElement.innerText = message;
          divElement.className = "mx-auto alert alert-danger";
          return;
    }
    // console.log(files);
    // let isVideo = files[0].path.includes(".mp4") | files[0].path.includes(".mp3") | files[0].path.includes(".webm");
    // Show as b64
    let reader = new FileReader();
    reader.onload = (e) => {
      // console.log(e.target.result);


      let newImage = {
        src: e.target.result,
      };
      this.setState({
        imagesInQueue: [
          ...this.state.imagesInQueue,
          newImage
        ],
      }, async () => {
        this.handleFileUpload(files);
      })
    }

    reader.readAsDataURL(files[0]);
  }

  processDoc = async (reqData) => {
    await axios.post(`${this.state.baseUrl}/lite/azureInvoice`, reqData, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }).then(async (response) => {
      this.setState({ isLoadingImages: false });
      console.log(response.data.invoice_id);
      let url = response.data.invoice_id;
      reqData.href = `#/invoice/${url}`;
      await this.setState({
        uploadedImages: [
          ...this.state.uploadedImages,
          reqData
        ],
      }, () => {
        this.updateParseCount();
        console.log(this.state.isCurrentUploads);
        console.log(this.state.uploadedImages.length);
      })
      // if (this.state.uploadedImages.length == this.state.listImages.length) {
      this.setState({ isImageuploaded: true });
      // }
      // window.location.href = `#/invoice/${url}`; // Put the id of invoice here. 
    }).catch(error => {
      console.error(error);
    });
  }

  processDocMulti = async (reqData1) => {

    let uploaded = reqData1.invoice

    await uploaded.concat(this.state.uploadedImages);
    if (uploaded.length > 5) {
      await this.setState({
        uploadedImages: uploaded
      }, () => {
        console.log(this.state.isCurrentUploads);
        console.log(this.state.uploadedImages.length);
        console.log(this.state.uploadedImages);
      })
    }
    await axios.post(`${this.state.baseUrl}/lite/azureInvoiceMulti`, reqData1, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }).then(async (response) => {
      this.setState({ isLoadingImages: false });
      console.log(response.data.invoice_id);
      let url = response.data.invoice_id;
      // reqData1.invoice.map((reqData)=>{
      // reqData.href = `#/invoice/${url}`;
      await this.setState({
        uploadedImages: uploaded
      }, () => {
        this.updateParseCount();
        console.log(this.state.isCurrentUploads);
        console.log(this.state.uploadedImages.length);
        console.log(this.state.uploadedImages);
      })
      // })
      // if (this.state.uploadedImages.length == this.state.listImages.length) {

    }).catch(error => {
      console.error(error);
      this.setState({
        uploadedImages: uploaded
      }, () => {
        console.log(this.state.isCurrentUploads);
        console.log(this.state.uploadedImages.length);
        console.log(this.state.uploadedImages);
      })
    });
  }
  handlePageLimit = () => {
    if (this.state.usedPage === this.state.pageLimit) {
      this.setState({
        pageLimitExisted: true,
      }, () => {
        console.log(this.state.pageLimitExisted + " called handlePageLimit");
        // You can perform any action that relies on the updated state here.
        if (this.state.pageLimitExisted==true) {
          var message = "You crossed the invoice parsing limit Please Contact Admin";
          var divElement = document.getElementById("msg");
          divElement.innerText = message;
          divElement.className = "mx-auto alert alert-danger";
          // document.getElementById("msg").innerText = message;
          // alert(`You can only upload a maximum of ${maxFiles} files.`);
          // return;
        }
      });
    }
  }
  componentDidMount = () => {
    this.updateParseCount();
    this.handlePageLimit();
    // console.log(this.state.pageLimitExisted);
  }

  render() {
    // const { loading } = this.state;

    return (
      <>

        {/* <div className="row g-3 m-0 mb-3">
          <div className="col mt-1">
            <div className="card bg-100 shadow-none border py-3">
              <div className="row gx-0 flex-between-center">
                <div className="col-sm-auto d-flex align-items-center">
                  <img
                    className="ms-n2"
                    src="../assets/img/illustrations/crm-bar-chart.png"
                    alt=""
                    width="90"
                  />
                  <div>
                    <h6 className="text-primary fs--1 mb-0">Welcome to </h6>
                    <h4 className="text-primary fw-bold mb-0">
                      Ze<span className="text-info fw-medium">Concile</span>
                    </h4>
                  </div>
                  <img
                    className="ms-n4 d-md-none d-lg-block"
                    src="../assets/img/illustrations/crm-line-chart.png"
                    alt=""
                    width="150"
                  />
                </div>
                <div className="col-md-auto p-3">

                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* Quick testing feature. */}
        <div className="row quick-testing-main g-3 m-0 mb-3">
          <div className="quick-testing">
            <div className="d-flex justify-content-space-between">
              <button
                className="d-flex justify-content-start mt-2 btn zeconcile-btn  rounded me-2"

                onClick={this.handleBack}
              >
                {"<"} Back To All Invoices
              </button>
              <div>
              <div className={`page-limit btn zeconcile-btn border rounded-pill px-2 ${(this.state.pageLimitExisted)?'bg-danger':'bg-success' } `}>
                Balance :
                {" " + (this.state.pageLimit-this.state.usedPage)} Out of {this.state.pageLimit}
              </div>
              </div>
            </div>
            <div>
              <h2 className="text-center"> New Invoice</h2>
            </div>
            <div className="quick-test-form">
              {/* <div className="formItem">
                <label className="quickform-label"> Mobile Number </label>
                <input className="form-control" type="tel" maxLength="10"
                  onChange={this.handlePhoneNumberChange}
                  value={this.state.phoneNumber} placeholder="Enter your 10 digit mobile number" />
              </div> */}

              <div className='card w-100 pb-2 mt-2'>
                <div className='card-body'>

                  <div className='row'>
                    <div className=''>
                      <Dropzone onDrop={files => this.dropzoneHandleNewFiles(files)} maxSize={9999999} disabled={this.state.pageLimitExisted} >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className="image-uploader-zone">
                            <input {...getInputProps()} />
                            <p>Drag 'n' drop some files here, or click to select files </p>
                          </div>
                        )}
                      </Dropzone>

                      {(this.state.isCurrentUploads > this.state.uploadedImages.length && this.state.uploadedImages.length < 6) ? (
                        <div className="d-flex justify-content-center">
                          <div className="loader"></div>
                        </div>
                      ) : (

                        <div className="images-uploading">
                          {/* {this.state.isImageuploaded &&(
                            <div className="mx-auto alert alert-success">
                              {this.state.uploadedImages.length} Invoices Scanned Successfully
                            </div>
                          )} */}
                          {(this.state.uploadedImages.length > 0 && this.state.uploadedImages.length == this.state.isCurrentUploads && this.state.uploadedImages.length < 6) ?
                            <div className="mx-auto">
                              <div className="mx-auto mb-1 alert alert-success">
                                {/* <span className=""> */}
                                {this.state.uploadedImages.length}{" "}
                                Invoices Scanned Successfully
                                {/* </span> */}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Link to="/home">

                                  <button
                                    className="mt-1 btn zeconcile-btn purple rounded-pill"
                                  // onClick={this.handleBack}
                                  >
                                    View All Invoice
                                  </button>
                                </Link>
                              </div>
                            </div> : (this.state.uploadedImages.length > 5) ? <div className="mx-auto">
                              <div className="mx-auto mb-1 alert alert-success">
                                {/* <span className=""> */}
                                {this.state.uploadedImages.length}{" "}
                                Invoices Parse take more time. Please check after 2-3 min.
                                {/* </span> */}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Link to="/home">

                                  <button
                                    className="mt-1 btn zeconcile-btn purple rounded-pill"
                                  // onClick={this.handleBack}
                                  >
                                    View All Invoice
                                  </button>
                                </Link>
                              </div>
                            </div> : ""
                          }
                          <div id="msg" className="mx-auto">
                          {this.state.pageLimitExisted
                          &&
                          <div id="msg" className="mx-auto alert alert-danger text-center">
                          You have exceeded the invoice parsing limit. kindly contact the administrative team for assistance. Thank you.
                          </div>
                          }
                          </div>

                          {/* Mini loader for invoice */}
                          {(this.state.isCurrentUploads > this.state.uploadedImages.length) ? (
                            <div className="d-flex justify-content-center">
                              <div className="loader"></div>
                            </div>
                          ) : ""}
                        </div>
                      )}

                    </div >
                    <div className='col-md-6'>
                      <div className="images-uploaded">

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="d-flex justify-content-center mt-2">

                <Link to="/home">

                  <button
                    className="mt-2 btn zeconcile-btn purple rounded-pill"
                  // onClick={this.handleBack}
                  >
                    Show All Invoice
                  </button>
                </Link>
              </div> */}


              {/* <div className="formItem">
                <label className="quickform-label"> Invoice File </label>
                <input className="form-control" type="file" onChange={this.handleFileInputChange} />
              </div>
              <div className="formItem">
                <button onClick={this.handleFileUpload} className="zeconcile-btn border mt-3 mx-auto d-block"> Process my Invoice file</button>
              </div> */}
            </div>
          </div>
        </div >
      </>
    );
  }
}

export default navigationHook(NewInvoice);
