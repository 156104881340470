import Companies from "../pages/companies/companies";
// import Invoice from "../pages/invoice";
import InvoiceDetail from "../pages/data/invoice-detail";
import SalesInvoices from "../pages/data/sales-invoices";
import Company from "../pages/company";

// import HomeComponent from "../pages/home";
import Login from "../pages/login/login";
import ChatPage from "../pages/ChatPage/ChatPage";
import AlldebtCred from "../pages/debt-cred"

import NewInvoice from "../pages/lite-pages/new-invoice";
import invoiceList from "../pages/lite-pages/invoice-list";
import Resgistration from "../pages/login/registration";

const authProtectedRoutes = [
  { path: "/invoices", component: invoiceList },
  { path: "/new-invoices", component: NewInvoice },
  { path: "/invoice/:id", component: InvoiceDetail },
  { path: "/sales-invoices", component: SalesInvoices },
  { path: "/companies", component: Companies },
  { path: "/company/:id", component: Company },
  { path: "/home", component: invoiceList },
  { path: "/chat", component: ChatPage },
  { path: "/debt-cred/:id", component: AlldebtCred },

];

const publicRoutes = [
  { path: "/register", component: Resgistration },
  { path: "/login", component: Login },
  { path: "/", component: Login },
];

export { publicRoutes, authProtectedRoutes };
