const Breadcrumb = ({ paths }) => {
    // console.log(paths[0].label) 
    return (
      <div className="zc-breadcrumb d-flex">
        <div className="bdcm_icon">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.69604 17.3218C9.07676 17.3218 10.196 16.2025 10.196 14.8218C10.196 13.4411 9.07676 12.3218 7.69604 12.3218C6.31533 12.3218 5.19604 13.4411 5.19604 14.8218C5.19604 16.2025 6.31533 17.3218 7.69604 17.3218Z" stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.696 17.3218C19.0768 17.3218 20.196 16.2025 20.196 14.8218C20.196 13.4411 19.0768 12.3218 17.696 12.3218C16.3153 12.3218 15.196 13.4411 15.196 14.8218C15.196 16.2025 16.3153 17.3218 17.696 17.3218Z" stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.696 7.32178C14.0768 7.32178 15.196 6.20249 15.196 4.82178C15.196 3.44107 14.0768 2.32178 12.696 2.32178C11.3153 2.32178 10.196 3.44107 10.196 4.82178C10.196 6.20249 11.3153 7.32178 12.696 7.32178Z" stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.696 22.3218C12.696 19.5604 10.4574 17.3218 7.69604 17.3218C4.93462 17.3218 2.69604 19.5604 2.69604 22.3218" stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.696 22.3218C22.696 19.5604 20.4574 17.3218 17.696 17.3218C14.9346 17.3218 12.696 19.5604 12.696 22.3218" stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.696 12.3218C17.696 9.56038 15.4574 7.32178 12.696 7.32178C9.93464 7.32178 7.69604 9.56038 7.69604 12.3218" stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div className="parent-breadcrumb item-breadcrumb">
            Invoices
        </div>
         {paths?.map((path) => {
            return(
                <div  className={`item-breadcrumb ${ path.active? "active": ""}`}>
                  <div className="bdcm_secondary_icon">
                    <svg width="7" height="12" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.19605 11.3218L6.19605 6.32178L1.19605 1.32178" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  {path.label}
                </div>
            )
        })} 
      </div>
    );
  };
  
  export default Breadcrumb;
  