

export const isGSTNo = (g) => {
  g = g.replace(/\s/g, "");
  const regex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/g;
  const found = g.match(regex);
  if (found) {
    if (found.length) {
      return true;
    }
  } else {
    return false;
  }
};

export const formateDate = (dateOriginal) => {
  dateOriginal =
    dateOriginal?.length === 8
      ? dateOriginal?.replace(
          /(\d{2})-(\d{2})-(\d{2})/,
          parseInt("$3") > 22 ? "$1/$2/19$3" : "$1/$2/20$3"
        )
      : dateOriginal?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3");

      dateOriginal =
    dateOriginal?.length === 8
      ? dateOriginal?.replace(
          /(\d{2})\/(\d{2})\/(\d{2})/,
          parseInt("$3") > 22 ? "$2/$1/19$3" : "$2/$1/20$3"
        )
      : dateOriginal?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3");
  console.log(dateOriginal.length);
  // data.inv_date = data.inv_date?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3");
  dateOriginal =  dateOriginal?.replace(/(\d+)(st|nd|rd|th)/, "$1");
  console.log(dateOriginal?.replace(/(\d+)(st|nd|rd|th)/, "$1"));
  let date = new Date(
    dateOriginal?.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
  );
  // console.log("");
  if(!date){
  }
  console.log(date);
  let yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // Months start at 0!
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  date = yyyy + "-" + mm + "-" + dd;
  // data.inv_date
  //   ? new Date(data.inv_date).toISOString().slice(0, 10)
  //   : "";
  return date;
};

export const getProductTableAsRowCSV = (item) => {
  if(item.product_table.length>0){
    let _products = [{
      "Date": "",
      "Invoice number": "",
      "Seller Name": "",
      "Seller GST": "",
      "Seller Address": "Product Name",
      "Buyer_Name": "HSN",
      "Buyer GST": "Price",
      "Buyer Address": "Qty",
      "Taxable Value": "Taxable Value",
      "CGST": "CGST",
      "SGST": "SGST",
      "IGST": "IGST",
      "Total Taxes":"Total Taxes",
      "Grand Total":"Grand Total"
    }];
    item.product_table.map((row)=>{
      let TotalTax = Number(row?.CGST || 0 ) + Number(row?.SGST || 0 ) + Number(row?.IGST || 0 );
      let _row ={
        "Date": "",
        "Invoice number": "",
        "Seller Name": "",
        "Seller GST": "",
        "Seller Address": row?.name,
        "Buyer_Name": (row.hsn)? Number(row.hsn):"",
        "Buyer GST": row?.price,
        "Buyer Address": row?.qty,
        "Taxable Value": row?.subtotal,
        "CGST": row?.CGST,
        "SGST": row?.SGST,
        "IGST": row?.IGST,
        "Total Taxes": TotalTax,
        "Grand Total": Number(row?.subtotal) + TotalTax
      } 
      _products.push(_row);
    })
    console.log(_products);
    return _products;
  }
};

