import React from "react";
import navigationHook from "../common/hooks";
// import { NavLink } from "react-router-dom";
import axios from "axios";
import { CSVLink } from "react-csv";
import "./companies/companies.css";
import Breadcrumb from "../common/breadcrums";
import AddCompanyModal from "./companies/add-debt-cred/add_company";
import EditCompanyModal from "./companies/edit-company/edit_company";
import EditCredDebt from "./companies/edit-debt-cred/edit_company";

// import AddDebtCred from "./companies/edit-company/addDebtCred";


class Company extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            baseUrl: process.env.REACT_APP_BASE_URL, //"http://localhost:8100/api",
            invoices: [],
            page: 1,
            query: [],
            gst: false,
            Company: props.params.id,
            totalPage: 1,
            company_data: [],
            current_branch: null,
            loader: true,
            csvLoader: false,
            allInvoices: [],
            uniqueInvoices: [],
            isBranchAvailable: true,
            type: 1,
            isFilter: true,
            showAddCompanyModal: false,
            setShowAddCompanyModal: false,
            showEditCompanyModal: false,
            setShowEditCompanyModal: false,
            showEditCredDebtModal: false,
            setShowEditCredDebtModal: false,
            modal_id: "-1",
            displayAlert: {
                display: false,
                status: false,
                message: "",
            },
            displayAlert2: {
                display: false,
                status: false,
                message: "",
            },
            companyId: props.params.id,
            party_type: "creditor",

        };

        localStorage.removeItem("fileKey");

        // this.getInvoices();
        console.log("this is companyId " + this.props.params);
    }
    // companyId = React.useRef({});
    csvLink = React.createRef();
    componentDidMount() {
        this.getCompany();
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        console.log("GOT ERROR");
        console.log(error);
        return { hasError: true };
    }

    handleEditModalClick = (id) => {
        this.setState({

            companyId: id,
            showEditCompanyModal: true,
        });
    };

    getCompany = async () => {
        try {
            this.setState({
                // invoices:[],
                loader: true,
                page: 1,
            });
            if (this.state.Company !== "null") {
                console.log(this.state.Company);
                console.log("get company");

                let response = await axios.get(
                    `${this.state.baseUrl}/company/` + this.state.Company,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
                let data = response.data.data;
                console.log(data);
                this.setState(
                    {
                        company_data: data,
                        current_branch: data.branches[0]?._id,
                    },
                    () => {
                        this.getCreditorDebtors();
                    }
                );
            } else {
                this.getCreditorDebtors();
            }

            // this.getParseData();
        } catch (e) {
            console.log(e);
        }
    };

    getCreditorDebtors = async () => {
        try {
            console.log("called CreditorDebtors");
            console.log(this.state.Company);
            let body = {
                // query: {
                company_id:
                    this.state.Company === "null" ? null : this.state.Company,
                type: this.state.party_type
                // },
            };

            let response = await axios.post(
                `${this.state.baseUrl}/company/cred-debt/` + this.state.page,
                body,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            let data = response.data.data;
            console.log(data);
            if (data.length > 0) {

                this.setState(
                    {
                        invoices: data,
                        uniqueInvoices: [],
                        isFilter: false,
                        totalPage: response.data.totalPage,
                        loader: false,
                        isBranchAvailable: true,
                    },
                    () => {
                        this.pagination();
                    }
                );
            } else {
                this.getInvoices();
            }
        } catch (e) {
            console.log(e);
        }
    };

    // delete Creditor-Debtor``

    deleteCredDebt = async (e) => {
        // console.log(e);
        console.log(e.target.getAttribute("data-id"));

        // console.log(this.setStates);
        let Id = (e.target.getAttribute("data-id"));
        // e.target.parentNode.getAttribute("data-id");
        const confirmed = window.confirm('Are you sure you want to delete this Creditor/Debtor?');
        if (confirmed) {
            try {
                const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/company/delete-cred-debt/${Id}`);
                console.log(response);
                if (response.data.status === "Success") {
                    alert("Creditor/Debtor deleted successfully");
                    this.getCreditorDebtors();
                } else {
                    console.log("Failed to delete company:", response.data);
                    alert("Failed to delete Creditor-Debtor");
                }
            } catch (error) {
                console.log("Failed to delete Creditor/Debtor:", error);
                alert("Failed to delete Creditor/Debtor");
            }
        }
    }


    getInvoices = async () => {
        console.log(this.state);
        try {
            console.log("called me");
            console.log(this.state.Company);
            let body = {
                query: {
                    client_company_id:
                        this.state.Company === "null" ? null : this.state.Company,
                },
            };

            if (this.state.Company !== null) {
                body = {
                    query: {
                        $and: [
                            {
                                client_company_id:
                                    this.state.Company === "null" ? null : this.state.Company,
                            },
                            {
                                company_branch_id:
                                    this.state.Company !== "null"
                                        ? this.state.current_branch
                                        : null,
                            },
                        ],
                    },
                };
            }
            if (this.state.isFilter) {
                body.query = {
                    client_company_id: this.state.Company,
                    company_branch_id: this.state.current_branch,
                    inv_type: this.state.type,
                };
                console.log(body);
                let response = await axios.put(
                    `${this.state.baseUrl}/invoice/company/` + this.state.page, body,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        }
                    }
                );
                console.log(response.data);
                let data = response.data;
                this.setState(
                    {
                        uniqueInvoices: data.data,
                        totalPage: data.totalPage,
                        loader: false,
                        isBranchAvailable: true,
                        isFilter: true,
                        invoices: data.data
                    },
                    () => {
                        this.pagination();
                    }
                );
            } else {
                let response = await axios.put(
                    `${this.state.baseUrl}/invoice/company/` + this.state.page,
                    body,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
                let data = response.data.data;
                console.log(data);

                this.setState(
                    {
                        invoices: data,
                        uniqueInvoices: [],
                        isFilter: false,
                        totalPage: response.data.totalPage,
                        loader: false,
                        isBranchAvailable: true,
                    },
                    () => {
                        this.pagination();
                    }
                );
            }
        } catch (e) {
            console.log(e);
        }
    };

    getAllInvoices = async (e) => {
        e.preventDefault();
        if (!this.state.csvLoader) {
            this.setState({ csvLoader: true });
            axios
                .get(`${this.state.baseUrl}/invoice-all/` + this.state.Company, null, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    let data = response.data.data;
                    console.log(response.data);
                    var allFilterInvoices = data.map((item) => ({
                        Particulars: item.counter_party,
                        "GSTIN/UIN": (item.buyer_gst) ? item.buyer_gst : item.counter_party_gst,
                        "Vch Type":
                            item.inv_type === 0
                                ? "UNCATEGORIZED"
                                : item.inv_type === 1
                                    ? "PURCHASE"
                                    : "SALE",
                        Invoice: item.inv_no,
                        Taxable: item.subtotal,
                        "Integrated Tax": item.taxes[0].IGST,
                        "Central Tax": item.taxes[0].CGST,
                        "State Tax": item.taxes[0].SGST,
                        "Total Tax":
                            item.taxes[0].IGST + item.taxes[0].CGST + item.taxes[0].SGST,
                    }));
                    this.setState(
                        { allInvoices: allFilterInvoices, uniqueInvoices: [] },
                        () => {
                            console.log(this.state.allInvoices);
                            this.forceUpdate();
                            this.csvLink.current.link.click();
                        }
                    );
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally((f) => {
                    this.setState({ csvLoader: false });
                });
        }
    };

    keyChange = async (e) => {
        let index = e.target.attributes.getNamedItem("data-key").value;
        console.log(index);
        localStorage.setItem("fileKey", index);
        this.getParseData();
    };

    pageChange = async (e) => {
        let p = e.target.attributes.getNamedItem("data-page").value;
        console.log(p);
        this.setState(
            {
                page: Number(p),
                loader: true,
                invoices: [],
            },
            () => {
                this.getInvoices();
            }
        );
    };

    handleBranchChange = async (event) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        await this.setState(
            {
                current_branch: value,
                loader: true,
                invoices: [],
            },
            () => {
                this.getInvoices();
            }
        );
        console.log(value);
    };

    componentWillReceiveProps(nextProps) {
        console.log("nextProps", nextProps);
        console.log(nextProps.params.id);
        this.setState(
            {
                Company: nextProps.params.id,
                invoices: [],
                company_data: [],
                isFilter: false,
            },
            () => {
                console.log("updated status ");
                this.getCompany();
            }
        );
    }

    dataFromListOfAllinvoices = () => {
        return this.state.allInvoices;
    };

    pagination = async () => {
        let menuItems = [];
        let pre = Number(this.state.page) - 1;
        let next = Number(this.state.page) + 1;

        if (this.state.totalPage <= 10) {
            menuItems = [];
            menuItems.push(
                <li className={`page-item ${pre <= 0 ? "disabled" : ""}`} key="pre">
                    <span
                        className="zeconcile-btn border-0 transparent "
                        data-page={pre}
                        onClick={pre <= 0 ? null : this.pageChange}
                    >
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.8735 4L6.87354 9L11.8735 14" stroke="#475569" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                </li>
            );
            for (var i = 0; i < this.state.totalPage; i++) {
                menuItems.push(
                    <li
                        key={i + 11}
                        className={`page-item ${this.state.page === i + 1 ? "active" : ""}`}
                    >
                        <span
                            className="page-link"
                            onClick={this.pageChange}
                            data-page={i + 1}
                        >
                            {i + 1}
                        </span>
                    </li>
                );
            }
        } else {
            menuItems = [];
            menuItems.push(
                <li className={`page-item ${pre <= 0 ? "disabled" : ""}`} key="pre">
                    <span
                        className="zeconcile-btn border-0 transparent"
                        data-page={pre}
                        onClick={pre <= 0 ? null : this.pageChange}
                    >
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.8735 4L6.87354 9L11.8735 14" stroke="#475569" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                </li>
            );
            if (this.state.page > 4 && this.state.page < this.state.totalPage - 2) {
                menuItems.push(
                    <li
                        key={"abd123"}
                        className={`page-item ${this.state.page === 1 ? "active" : ""}`}
                    >
                        <span className="page-link" onClick={this.pageChange} data-page={1}>
                            1
                        </span>
                    </li>,
                    <li
                        key={"abd124l"}
                        className={`page-item ${this.state.page === 1 ? "active" : ""}`}
                    >
                        <span
                            className="page-link"
                            // onClick={this.pageChange}
                            data-page="..."
                        >
                            ...
                        </span>
                    </li>
                );

                menuItems.push(
                    <li key={"abdl125"} className={`page-item`}>
                        <span
                            className="page-link"
                            onClick={this.pageChange}
                            data-page={this.state.page - 2}
                        >
                            {this.state.page - 2}
                        </span>
                    </li>
                );

                menuItems.push(
                    <li key={"abd125"} className={`page-item`}>
                        <span
                            className="page-link"
                            onClick={this.pageChange}
                            data-page={this.state.page - 1}
                        >
                            {this.state.page - 1}
                        </span>
                    </li>,
                    <li key={"abd126"} className={`page-item active`}>
                        <span
                            className="page-link"
                            onClick={this.pageChange}
                            data-page={this.state.page}
                        >
                            {this.state.page}
                        </span>
                    </li>,
                    <li key={"abd127"} className={`page-item`}>
                        <span
                            className="page-link"
                            onClick={this.pageChange}
                            data-page={this.state.page + 1}
                        >
                            {this.state.page + 1}
                        </span>
                    </li>,
                    <li key={"abd1281"} className={`page-item`}>
                        <span
                            className="page-link"
                            onClick={this.pageChange}
                            data-page={this.state.page + 2}
                        >
                            {this.state.page + 2}
                        </span>
                    </li>,
                    <li
                        key={"abd124"}
                        className={`page-item ${this.state.page === 1 ? "active" : ""}`}
                    >
                        <span
                            className="page-link"
                            // onClick={this.pageChange}
                            data-page="..."
                        >
                            ...
                        </span>
                    </li>,
                    <li
                        key={"abd128"}
                        className={`page-item ${this.state.page === this.state.totalPage ? "active" : ""
                            }`}
                    >
                        <span
                            className="page-link"
                            onClick={this.pageChange}
                            data-page={this.state.totalPage}
                        >
                            {this.state.totalPage}
                        </span>
                    </li>
                );
            } else {
                for (var j = 0; j < 5; j++) {
                    menuItems.push(
                        <li
                            key={j + 11}
                            className={`page-item ${this.state.page === j + 1 ? "active" : ""
                                }`}
                        >
                            <span
                                className="page-link"
                                onClick={this.pageChange}
                                data-page={j + 1}
                            >
                                {j + 1}
                            </span>
                        </li>
                    );
                }

                menuItems.push(
                    <li key={"abd129"} className={`page-item`}>
                        <span
                            className="page-link"
                            // onClick={this.pageChange}
                            data-page="..."
                        >
                            ...
                        </span>
                    </li>,
                    <li
                        key={"abd1201a"}
                        className={`page-item ${this.state.page === this.state.totalPage - 3 ? "active" : ""
                            }`}
                    >
                        <span
                            className="page-link"
                            onClick={this.pageChange}
                            data-page={this.state.totalPage - 3}
                        >
                            {this.state.totalPage - 3}
                        </span>
                    </li>,
                    <li
                        key={"abd1201"}
                        className={`page-item ${this.state.page === this.state.totalPage - 3 ? "active" : ""
                            }`}
                    >
                        <span
                            className="page-link"
                            onClick={this.pageChange}
                            data-page={this.state.totalPage - 2}
                        >
                            {this.state.totalPage - 2}
                        </span>
                    </li>,
                    <li
                        key={"abd1202"}
                        className={`page-item ${this.state.page === this.state.totalPage - 1 ? "active" : ""
                            }`}
                    >
                        <span
                            className="page-link"
                            onClick={this.pageChange}
                            data-page={this.state.totalPage - 1}
                        >
                            {this.state.totalPage - 1}
                        </span>
                    </li>,
                    <li
                        key={"abd1210"}
                        className={`page-item ${this.state.page === this.state.totalPage ? "active" : ""
                            }`}
                    >
                        <span
                            className="page-link"
                            onClick={this.pageChange}
                            data-page={this.state.totalPage}
                        >
                            {this.state.totalPage}
                        </span>
                    </li>
                );
            }
        }
        menuItems.push(
            <li
                key="next"
                className={`page-item ${next > this.state.totalPage ? "disabled" : ""}`}
            >
                <span className="zeconcile-btn border-0 transparent" onClick={this.pageChange} data-page={next}>
                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.87354 14L11.8735 9L6.87354 4" stroke="#475569" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
            </li>
        );

        await this.setState({
            menuItems: menuItems,
        });
    };

    render() {
        const {
            // setshowEditCredDebtModal,
            showEditCredDebtModal,
            showAddCompanyModal,
            showEditCompanyModal,
            companyId,
            // credDebtId,
        } = this.state;
        console.log(this.state);
        let menuItems = [];
        let pre = Number(this.state.page) - 1;
        let next = Number(this.state.page) + 1;
        menuItems.push(
            <li className={`page-item ${pre <= 0 ? "disabled" : ""}`} key="pre">
                <span
                    className="page-link"
                    data-page={pre}
                    onClick={pre <= 0 ? null : this.pageChange}
                >
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.1963 4.32178L7.19629 9.32178L12.1963 14.3218" stroke="#5945B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </span>
            </li>
        );
        for (var i = 0; i < this.state.totalPage; i++) {
            menuItems.push(
                <li
                    key={i + 11}
                    className={`page-item ${this.state.page === i + 1 ? "active" : ""}`}
                >
                    <span
                        className="page-link"
                        onClick={this.pageChange}
                        data-page={i + 1}
                    >
                        {i + 1}
                    </span>
                </li>
            );
        }
        menuItems.push(
            <li
                key="next"
                className={`page-item ${next > this.state.totalPage ? "disabled" : ""}`}
            >
                <span className="zeconcile-btn border-0 transparent" onClick={this.pageChange} data-page={next}>
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.19629 14.3218L12.1963 9.32178L7.19629 4.32178" stroke="#5945B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </span>
            </li>
        );

        return (
            <>

                {showEditCredDebtModal ? (
                    <EditCredDebt
                        show={showEditCredDebtModal}
                        companyId={companyId}
                        // companyId={this.state.modal_id}
                        setShowEditCredDebtModal={(value) =>
                            this.setState({ showEditCredDebtModal: value })
                        }
                        setDisplayAlert2={(value) => this.setState({ displayAlert2: value })}
                    />
                ) : null}

                {showEditCompanyModal ? (
                    <EditCompanyModal
                        show={showEditCompanyModal}
                        companyId={companyId}
                        setShowEditCompanyModal={(value) =>
                            this.setState({ showEditCompanyModal: value })
                        }
                        setDisplayAlert2={(value) => this.setState({ displayAlert2: value })}
                    />
                ) : null}
                {showAddCompanyModal && (
                    <AddCompanyModal
                        show={showAddCompanyModal}
                        companyId={this.props.params.id}
                        setShowAddCompanyModal={(value) =>
                            this.setState({ showAddCompanyModal: value, })
                        }
                        setDisplayAlert={(value) => this.setState({ displayAlert: value })}
                    />
                )}
                {/* <Breadcrumb paths={[{ label: "All Client", active: false },{ label: this.state.company_data.name, active: true }]} ></Breadcrumb> */}
                <div className="p-2">
                    <Breadcrumb paths={[]} ></Breadcrumb>
                </div>
                <div className="row g-3 m-0 my-3  py-0">
                    <div className="col-lg-4 m-0 text-capitalize" >
                        <h4>
                            {this.state.company_data.name}
                        </h4>
                    </div>
                    {/* // ? this.state.company_data.name
                    // : "Invoices"} */}
                    <div className="col-lg-8 text-lg-end mt-0">
                        <button className="zeconcile-btn purple px-4 me-2"
                            onClick={() => this.setState({ showEditCompanyModal: true })}

                        >
                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_176_12401)">
                                    <path d="M1.15723 9C1.15723 9 4.15723 3 9.40723 3C14.6572 3 17.6572 9 17.6572 9C17.6572 9 14.6572 15 9.40723 15C4.15723 15 1.15723 9 1.15723 9Z" stroke="#5945B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9.40723 11.25C10.6499 11.25 11.6572 10.2426 11.6572 9C11.6572 7.75736 10.6499 6.75 9.40723 6.75C8.16459 6.75 7.15723 7.75736 7.15723 9C7.15723 10.2426 8.16459 11.25 9.40723 11.25Z" stroke="#5945B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_176_12401">
                                        <rect width="18" height="18" fill="white" transform="translate(0.407227)" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <span className="ps-1">
                                View details
                            </span>
                        </button>
                        <button className="zeconcile-btn purple start-0 px-4 ms-1 me-2"
                            onClick={() => this.setState({ showEditCredDebtModal: true })}

                        >
                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.03223 15.75H16.5322" stroke="#5945B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4.53223 10.02V12.75H7.27618L15.0322 4.99054L12.2929 2.25L4.53223 10.02Z" stroke="#5945B3" stroke-width="1.5" stroke-linejoin="round" />
                            </svg>
                            <span className="ps-1">
                                Edit details
                            </span>
                        </button>

                        <button
                            onClick={this.getAllInvoices}
                            className="d-none zeconcile-btn mx-2 transparent {
                "
                        >
                            {this.state.csvLoader
                                ? "Loading csv..."
                                : "Download Invoices"}
                        </button>
                        <CSVLink
                            data={this.state.allInvoices}
                            ref={this.csvLink}
                            filename={`${this.state.company_data.name} Invoices`}
                        ></CSVLink>
                        <button
                            className="btn zeconcile-btn"
                            type="button"
                            onClick={() => this.setState({ showAddCompanyModal: true })}

                        // onClick={() => setShowAddCompanyModal(true)}
                        >
                            <span> Add New Debtors & Creditors </span>
                            <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Icon/ 18px/ Arrow Right">
                                    <path id="Vector" d="M7.19629 14.3218L12.1963 9.32178L7.19629 4.32178" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                            </svg>

                        </button>
                    </div>
                </div>
                <div className="row  g-3 m-0  py-0 my-3 mt-2">

                    <div className=" col-lg-9 d-flex justify-content-sm-between gap-3">
                        <div className="filter-check-controller debt-bg" >

                            {/* old code  */}
                            <div className="mx-0">
                                <button type="radio"
                                    id="sale"
                                    name="invoiceType"
                                    onClick={() => {
                                        this.setState(
                                            { party_type: "creditor", type: 1, page: 1, isFilter: true },
                                            () => {
                                                this.getCreditorDebtors();
                                            }
                                        );
                                    }}
                                    className={`clr-purple trans border-0 py-2 px-4  ${this.state.type === 1 ? 'btn activ' : ''}`}
                                // className="clr-purple trans border-0 px-4 active"


                                >
                                    Creditors  </button>

                            </div>
                            <div className=" me-1">
                                <button
                                    id="purchases"
                                    name="invoiceType"

                                    // className="zeconcile-btn clr-purple trans px-4"
                                    // className="zeconcile-btn clr-purple transparent px-4"
                                    onClick={() => {
                                        this.setState(
                                            { party_type: "debtor", type: 2, page: 1, isFilter: true },
                                            () => {
                                                this.getCreditorDebtors();
                                            }
                                        );
                                    }}
                                    className={`clr-purple trans border-0 py-2 px-4  ${this.state.type === 2 ? 'btn activ' : ''}`}


                                >  Debtors</button>
                                {/* <label className="label"></label> */}

                            </div>

                        </div>
                    </div>
                    <div className="col-lg-3 d-flex  zc-invoice-list-controller-search">
                        {/* <form class="ms-2"> */}
                        <input class="form-control" type="search" placeholder="Search" aria-label="Search" />
                        {/* <button class="btn btn-outline-success" type="submit">Search</button> */}
                        {/* </form> */}

                    </div>
                </div>
                <div className="row g-3 m-0 mb-3 py-0">
                    <div
                        className={
                            this.state.Company !== "null"
                                ? "d-flex justify-content-end mt-1"
                                : "d-flex justify-content-end mt-1 d-none"
                        }
                    >
                        <select
                            className={
                                this.state.company_data?.branches?.length > 0
                                    ? "d-none"
                                    : "d-none"
                            }
                            name="current_branch"
                            onChange={this.handleBranchChange}
                            value={this.state.current_branch}
                            aria-label="State"
                        >
                            {this.state.company_data.branches?.map((item, index) => (
                                <option key={item._id} value={item._id}>
                                    {item.branch_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="scrollbar">
                        <table className="zeconcile-table">
                            <thead>
                                <tr>
                                    <th ><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                                    </svg>
                                        Party Name</th>
                                    <th><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                                    </svg>
                                        GSTIN/UIN</th>
                                    <th><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                                    </svg>
                                        State</th>
                                    <th><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                                    </svg>
                                        Creation Source</th>
                                    <th><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                                    </svg>
                                        Created Date / Time</th>
                                    <th><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                                    </svg>
                                        Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.invoices.map((item, index) => (
                                    <tr>
                                        <td>

                                            {item.name ? (
                                                item.name
                                            ) : item.counter_party ? item.counter_party : (
                                                <p style={{ color: "red" }}>Details Missing</p>
                                            )}


                                        </td>
                                        <td>
                                            {(item.gst) ? item.gst : (item.buyer_gst) ? item.buyer_gst : item.counter_party_gst}
                                        </td>
                                        <td>
                                            {item?.state}
                                        </td>
                                        <td>
                                            {(item.created_source) ? item.created_source : "Manual"}
                                        </td>
                                        <td>
                                            {new Date(item.createdAt).toLocaleTimeString() +
                                                " " +
                                                new Date(item.createdAt).toLocaleDateString()}
                                        </td>

                                        <td>
                                            <span data-id={item._id}
                                                onClick={this.deleteCredDebt}
                                            >
                                                {/* {item.grand_total} */}
                                                <svg data-id={item._id} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.13184 5.125V22.125H20.1318V5.125H5.13184Z" stroke="#5945B3" stroke-width="2" stroke-linejoin="round" />
                                                    <path d="M10.6318 10.125V16.625" stroke="#5945B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14.6318 10.125V16.625" stroke="#5945B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M2.63184 5.125H22.6318" stroke="#5945B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M8.63184 5.125L10.2763 2.125H15.0204L16.6318 5.125H8.63184Z" stroke="#5945B3" stroke-width="2" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                            <button className="border-0 bg-transparent"

                                                onClick={() => this.setState({ showEditCredDebtModal: true, modal_id: item._id })}

                                            >
                                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.125 9.34174V12.0718H3.86895L11.625 4.31231L8.88566 1.57178L1.125 9.34174Z" stroke="#475569" stroke-width="1.5" stroke-linejoin="round" />
                                                </svg>

                                            </button>

                                        </td>
                                    </tr>
                                ))}
                                {
                                    this.state.invoices.length < 1 &&
                                        this.state.loader === false ? (
                                        <tr key="-3">
                                            <td colSpan="7">No Invoice Found</td>
                                        </tr>
                                    ) : this.state.loader === true ? (
                                        <tr key="-2">
                                            <td colSpan="7" style={{ textAlign: "center" }}>
                                                <div className="lds-ellipsis">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            </td>
                                        </tr>
                                    ) : (
                                        ""
                                    )
                                }
                            </tbody>
                            {this.state.totalPage > 1 ? (
                                <tfoot key="110">

                                    <tr key="11001" className="p-2" >
                                        <td colSpan="8" className="p-0 pt-3">
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination justify-content-end ms-2">
                                                    {this.state.menuItems}
                                                </ul>
                                            </nav>
                                        </td>
                                    </tr>
                                </tfoot>
                            ) : (
                                ""
                            )}
                        </table>
                    </div>

                </div>
            </>
        );
    }
}

export default navigationHook(Company);

