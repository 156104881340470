import React from "react";
import navigationHook from "../../common/hooks";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { CSVLink } from "react-csv";
import "../companies/companies.css";
// import "../data/invoice.css";
import Breadcrumb from "../../common/breadcrums";
// import { Search } from "semantic-ui-react";
import mixpanel from "mixpanel-browser";
import { getUserCount } from "../../common/function";
import { getProductTableAsRowCSV } from "../../common/Utils";

class InvoiceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseUrl: process.env.REACT_APP_BASE_URL, //"http://localhost:8100/api",
      invoices: [],
      page: 1,
      query: [],
      gst: false,
      Company: props.params.id,
      totalPage: 1,
      company_data: [],
      current_branch: null,
      loader: true,
      csvLoader: false,
      allInvoices: [],
      uniqueInvoices: [],
      isBranchAvailable: true,
      type: 0,
      usedPage: 0,
      pageLimit: 20,
      pageLimitExisted: false,
      isFilter: false,
      recentClients: [],
      invoiceFound: false,
    };
    localStorage.removeItem("fileKey");
    // this.getInvoices();
    console.log(this.props);
  }
  csvLink = React.createRef();

  componentDidMount() {
    this.getInvoices();
    this.updateParseCount();
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log("GOT ERROR");
    console.log(error);
    return { hasError: true };
  }

  updateParseCount = async ()=>{
    let data = await getUserCount();
    console.log(data);
    if(data.status == "Success"){
      this.setState({
        usedPage: data.data.total_page_parsed,
        pageLimit: data.data.max_allowed_parse_page,
        pageLimitExisted: (data.data.max_allowed_parse_page==data.data.total_page_parsed || data.data.max_allowed_parse_page<=data.data.total_page_parsed)? true : false
      })
    }
  }
  // componentDidCatch(error, errorInfo) {
  //     // You can also log the error to an error reporting service
  //     console.log(error, errorInfo);
  // }

  getInvoices = async () => {
    console.log(this.state);
    try {
      console.log("called me");
      console.log(this.state.Company);
      let body = {
        query: {
          client_company_id:
            this.state.Company === "null" ? null : this.state.Company,
        },
      };
      if (this.state.Company !== null) {
        body = {
          query: {},
        };
      }
      if (this.state.isFilter) {
        body.query = {
          inv_type: this.state.type
        };
        console.log(body);
        if (this.state.status) { body.query.status = this.state.status; }

        let response = await axios.put(
          `${this.state.baseUrl}/org/invoice/` + this.state.page, body,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
          }
        );
        console.log(response.data);
        let data = response.data;
        this.setState(
          {
            uniqueInvoices: data.data,
            totalPage: data.totalPage,
            loader: false,
            isBranchAvailable: true,
            isFilter: true,
            invoices: data.data
          },
          () => {
            this.pagination();
            this.updateParseCount();
          }
        );
      } else {
        if (this.state.isStatusFilter) {
          body.query.status = this.state.status
        }

        let response = await axios.put(
          `${this.state.baseUrl}/org/invoice/` + this.state.page, body,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
          }
        );
        let data = response.data.data;
        console.log(data);
        this.setState(
          {
            invoices: data,
            uniqueInvoices: [],
            isFilter: false,
            totalPage: response.data.totalPage,
            loader: false,
            isBranchAvailable: true,
          },
          () => {
            this.pagination();
            if (this.state.invoices.length < 1 && !this.state.invoiceFound) {
              this.setState(
                { invoiceFound: true }
              )
            }
          }
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
  getSearchInvoice = (opt) => {
    console.log(opt);

    if (opt) {
      const axios = require('axios');
      let data = JSON.stringify({
        "search": opt,
        client_company_id: this.state.Company,

        // "client_company_id": this.state.company
      });

      let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${this.state.baseUrl}/org/invoice/search/` + this.state.page,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          let data = response.data;
          console.log(data);

          this.setState(
            {
              uniqueInvoices: data.data,
              totalPage: data.totalPage,
              loader: false,
              isBranchAvailable: true,
              isFilter: true,
              invoices: data.data
            },
            () => {
              this.pagination();
            }
          );
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("loading");
    }
  };

  handleSearchSubmit = (event) => {
    event.preventDefault();
    console.log(event);
    const searchTerm = event.target.value;
    this.setState(
      {
        page: 1,
        loader: true,
        invoices: [],
      },
      () => {
        if (searchTerm == "") {
          this.getInvoices();
        } else {
          this.getSearchInvoice(searchTerm);
        }
      }
    );
  };
  getAllInvoices = async (e) => {
    mixpanel.init('c45b41f7cb0c1d55172b2375d52dfc5e');

    e.preventDefault();
    if (!this.state.csvLoader) {
      this.setState({ csvLoader: true });
      axios
        .get(`${this.state.baseUrl}/org/invoice-all/`, null, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          let data = response.data.data;
          console.log(response.data.status);
          let email = localStorage.getItem('zc_userEmail');

          var allFilterInvoices = [];
          data.map((item) => {
            let _item = {
            "Date": new Date(item.createdAt).toLocaleDateString(),
            "Invoice number": item.inv_no,
            "Seller Name": item.client_company,
            "Seller GST": item.seller_gst,
            "Seller Address": item.client_company_address,
            "Buyer_Name": item.counter_party,
            "Buyer GST": (item.buyer_gst) ? item.buyer_gst : item.counter_party_gst,
            "Buyer Address": item.counter_party_address,
            // "Vch Type":
            //   item.inv_type === 0
            //     ? "UNCATEGORIZED"
            //     : item.inv_type === 1
            //       ? "PURCHASE"
            //       : "SALE",
            // Invoice: item.inv_no,
            "Taxable Value": item.subtotal,
            "CGST": item.taxes[0].CGST,
            "SGST": item.taxes[0].SGST,
            "IGST": item.taxes[0].IGST,
            "Total Taxes":
              item.taxes[0].IGST + item.taxes[0].CGST + item.taxes[0].SGST,
            "Grand Total": item.grand_total,
          }
          allFilterInvoices.push(_item);
          if(item.product_table.length>0){
            let _product = getProductTableAsRowCSV(item);
            console.log("_product");
            allFilterInvoices = [...allFilterInvoices,{},..._product,{}];
          }
        });

          this.setState(
            { allInvoices: allFilterInvoices, uniqueInvoices: [] },
            () => {
              console.log(this.state.allInvoices);
              this.forceUpdate();
              this.csvLink.current.link.click();
              if (this.state.invoices.length < 1 && !this.state.invoiceFound) {
                this.setState(
                  { invoiceFound: true }
                )
              }
            }
          );
          if (response.data.status === 'Success') {
            mixpanel.track('DOWNLOAD ALL INVOICE', { email });
            mixpanel.people.set({ '$email': response.data.data.email });

          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally((f) => {
          this.setState({ csvLoader: false });
        });
    }
  };

  keyChange = async (e) => {
    let index = e.target.attributes.getNamedItem("data-key").value;
    console.log(index);
    localStorage.setItem("fileKey", index);
    this.getParseData();
  };
  logoutHandler = () => {

    localStorage.removeItem("token");
    window.location.reload(false);

  };
  pageChange = async (e) => {
    let p = e.target.attributes.getNamedItem("data-page").value;
    console.log(p);
    this.setState(
      {
        page: Number(p),
        loader: true,
        invoices: [],
      },
      () => {
        this.getInvoices();
      }
    );
  };
  deleteAllInvoice = async (e) => {
    // console.log(e);
    console.log(e.target.getAttribute("data-id"));

    // console.log(this.setStates);
    let Id = (e.target.getAttribute("data-id"));
    // e.target.parentNode.getAttribute("data-id");
    const confirmed = window.confirm('Are you sure you want to delete All Invoice ?');
    if (confirmed) {
      try {
        const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/org/delete-all/org-invoices/`);
        console.log(response);
        if (response.data.status === "Success") {
          alert("All Invoice deleted successfully");
          this.getInvoices();
        } else {
          console.log("Failed to delete company:", response.data);
          alert("Failed to delete All Invoice");
        }
      } catch (error) {
        console.log("Failed to delete All Invoice:", error);
        alert("Failed to delete All Invoice");
      }
    }
  }
  handleBranchChange = async (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    await this.setState(
      {
        current_branch: value,
        loader: true,
        invoices: [],
      },
      () => {
        this.getInvoices();
      }
    );
    console.log(value);
  };

  componentWillReceiveProps(nextProps) {
    console.log("nextProps", nextProps);
    console.log(nextProps.params.id);
    this.setState(
      {
        Company: nextProps.params.id,
        invoices: [],
        company_data: [],
        isFilter: false,
      },
      () => {
        console.log("updated status ");
        // this.getCompany();
      }
    );
  }

  // dataFromListOfAllinvoices = () => {
  //   return this.state.allInvoices;
  // };
  pageLimit = () => {
    if (this.state.usedPage === this.state.pageLimit) {
      window.alert("You have exceeded the invoice parsing limit; kindly contact the administrative team for assistance. Thank you.");
    }else{
      console.log("called" );
      window.location.href='/#/new-invoices/';
    }
  }

  pagination = async () => {
    let menuItems = [];
    let pre = Number(this.state.page) - 1;
    let next = Number(this.state.page) + 1;

    if (this.state.totalPage <= 10) {
      menuItems = [];
      menuItems.push(
        <li className={`page-item ${pre <= 0 ? "disabled" : ""}`} key="pre">
          <span
            className="next-pre zeconcile-btn transparent "
            data-page={pre}
            onClick={pre <= 0 ? null : this.pageChange}
          >
            <svg width="19" height="18" viewBox="0 0 19 18" data-page={pre}  fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.8735 4L6.87354 9L11.8735 14" stroke="#475569" stroke-width="1.5" stroke-linecap="round" strokeLinejoin="round" />
            </svg>
          </span>
        </li>
      );
      for (var i = 0; i < this.state.totalPage; i++) {
        menuItems.push(
          <li
            key={i + 11}
            className={`page-item ${this.state.page === i + 1 ? "active" : ""}`}
          >
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={i + 1}
            >
              {i + 1}
            </span>
          </li>
        );
      }
    } else {
      menuItems = [];
      menuItems.push(
        <li className={`page-item ${pre <= 0 ? "disabled" : ""}`} key="pre">
          <span
            className="zeconcile-btn border-0 transparent"
            data-page={pre}
            onClick={pre <= 0 ? null : this.pageChange}
          >
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.8735 4L6.87354 9L11.8735 14" stroke="#475569" stroke-width="1.5" stroke-linecap="round" strokeLinejoin="round" />
            </svg>
          </span>
        </li>
      );
      if (this.state.page > 4 && this.state.page < this.state.totalPage - 2) {
        menuItems.push(
          <li
            key={"abd123"}
            className={`page-item ${this.state.page === 1 ? "active" : ""}`}
          >
            <span className="page-link" onClick={this.pageChange} data-page={1}>
              1
            </span>
          </li>,
          <li
            key={"abd124l"}
            className={`page-item ${this.state.page === 1 ? "active" : ""}`}
          >
            <span
              className="page-link"
              // onClick={this.pageChange}
              data-page="..."
            >
              ...
            </span>
          </li>
        );

        menuItems.push(
          <li key={"abdl125"} className={`page-item`}>
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={this.state.page - 2}
            >
              {this.state.page - 2}
            </span>
          </li>
        );

        menuItems.push(
          <li key={"abd125"} className={`page-item`}>
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={this.state.page - 1}
            >
              {this.state.page - 1}
            </span>
          </li>,
          <li key={"abd126"} className={`page-item active`}>
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={this.state.page}
            >
              {this.state.page}
            </span>
          </li>,
          <li key={"abd127"} className={`page-item`}>
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={this.state.page + 1}
            >
              {this.state.page + 1}
            </span>
          </li>,
          <li key={"abd1281"} className={`page-item`}>
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={this.state.page + 2}
            >
              {this.state.page + 2}
            </span>
          </li>,
          <li
            key={"abd124"}
            className={`page-item ${this.state.page === 1 ? "active" : ""}`}
          >
            <span
              className="page-link"
              // onClick={this.pageChange}
              data-page="..."
            >
              ...
            </span>
          </li>,
          <li
            key={"abd128"}
            className={`page-item ${this.state.page === this.state.totalPage ? "active" : ""
              }`}
          >
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={this.state.totalPage}
            >
              {this.state.totalPage}
            </span>
          </li>
        );
      } else {
        for (var j = 0; j < 5; j++) {
          menuItems.push(
            <li
              key={j + 11}
              className={`page-item ${this.state.page === j + 1 ? "active" : ""
                }`}
            >
              <span
                className="page-link"
                onClick={this.pageChange}
                data-page={j + 1}
              >
                {j + 1}
              </span>
            </li>
          );
        }

        menuItems.push(
          <li key={"abd129"} className={`page-item`}>
            <span
              className="page-link"
              // onClick={this.pageChange}
              data-page="..."
            >
              ...
            </span>
          </li>,
          <li
            key={"abd1201a"}
            className={`page-item ${this.state.page === this.state.totalPage - 3 ? "active" : ""
              }`}
          >
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={this.state.totalPage - 3}
            >
              {this.state.totalPage - 3}
            </span>
          </li>,
          <li
            key={"abd1201"}
            className={`page-item ${this.state.page === this.state.totalPage - 3 ? "active" : ""
              }`}
          >
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={this.state.totalPage - 2}
            >
              {this.state.totalPage - 2}
            </span>
          </li>,
          <li
            key={"abd1202"}
            className={`page-item ${this.state.page === this.state.totalPage - 1 ? "active" : ""
              }`}
          >
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={this.state.totalPage - 1}
            >
              {this.state.totalPage - 1}
            </span>
          </li>,
          <li
            key={"abd1210"}
            className={`page-item ${this.state.page === this.state.totalPage ? "active" : ""
              }`}
          >
            <span
              className="page-link"
              onClick={this.pageChange}
              data-page={this.state.totalPage}
            >
              {this.state.totalPage}
            </span>
          </li>
        );
      }
    }
    menuItems.push(
      <li
        key="next"
        className={`page-item ${next > this.state.totalPage ? "disabled" : ""}`}
      >
        <span className="next-pre zeconcile-btn  transparent" onClick={this.pageChange} data-page={next}>
          <svg width="19" height="18" viewBox="0 0 19 18" data-page={next} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.87354 14L11.8735 9L6.87354 4" stroke="#475569" stroke-width="1.5" stroke-linecap="round" strokeLinejoin="round" />
          </svg>
        </span>
      </li>
    );

    await this.setState({
      menuItems: menuItems,
    });
  };

  render() {
    const status = this.state.status === 0 ? "Un-Approved" : this.state.status === 1 ? "Approved" : "Status";

    console.log(this.state);
    let menuItems = [];
    let pre = Number(this.state.page) - 1;
    let next = Number(this.state.page) + 1;
    menuItems.push(
      <li className={`page-item ${pre <= 0 ? "disabled" : ""}`} key="pre">
        <span
          className="page-link"
          data-page={pre}
          onClick={pre <= 0 ? null : this.pageChange}
        >
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1963 4.32178L7.19629 9.32178L12.1963 14.3218" stroke="#5945B3" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>

        </span>
      </li>
    );
    for (var i = 0; i < this.state.totalPage; i++) {
      menuItems.push(
        <li
          key={i + 11}
          className={`page-item ${this.state.page === i + 1 ? "active" : ""}`}
        >
          <span
            className="page-link"
            onClick={this.pageChange}
            data-page={i + 1}
          >
            {i + 1}
          </span>
        </li>
      );
    }
    menuItems.push(
      <li
        key="next"
        className={`page-item ${next > this.state.totalPage ? "disabled" : ""}`}
        data-page={next}
      >
        <span className="zeconcile-btn border-0 transparent" onClick={this.pageChange} data-page={next}>
          <svg width="19" height="19" viewBox="0 0 19 19" data-page={next} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.19629 14.3218L12.1963 9.32178L7.19629 4.32178" data-page={next} stroke="#5945B3" stroke-width="1.5" stroke-linecap="round" strokeLinejoin="round" />
          </svg>

        </span>
      </li>
    );
    return (
      <>

        <div className="p-2 d-flex justify-content-space-between">
          <Breadcrumb paths={[]} ></Breadcrumb>
          <div className="dark__bg-1000 rounded-2 py-2">
            <div className={`bg-success btn zeconcile-btn border rounded-pill px-2 me-2 ${this.state.pageLimitExisted ? 'bg-danger':'bg-success'}`}>
            Balance : 
              {" " + (this.state.pageLimit-this.state.usedPage)} Out of {this.state.pageLimit}
            </div>
            <button
              //  style={{
              //   background: "#141E30", /* fallback for old browsers */
              //   background: "-webkit-linear-gradient(to right, #243B55, #141E30)", /* Chrome 10-25, Safari 5.1-6 */
              //   background: "linear-gradient(to right, #243B55, #141E30)", /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
              //   color: "#fff",
              //   border: "3px solid #eee"
              // }}
              className="btn zeconcile-btn purple rounded-pill"
              onClick={this.logoutHandler}
            >
              Logout
            </button>
          </div>
          {/* <h4 className="mt-2">logout</h4> */}

        </div>
        <div className={(this.state.invoiceFound == "false" || this.state.invoiceFound == false) ? "row m-0 ms-2 my-3" : "d-none"}>
          <h4 className="mb-0 p-1 text-capitalize me-10">
            {this.state.company_data.name
              ? this.state.company_data.name
              : "Invoices"}
          </h4>

        </div>
        <div className={(this.state.invoiceFound == "false" || this.state.invoiceFound == false) ? "col-12 my-3 d-flex px-2 " : "d-none"}>
          <div className="col-3 d-flex zc-invoice-list-controller-search ps-0">
            <input onChange={this.handleSearchSubmit} className="py-0 form-control" type="search" placeholder="Search" aria-label="Search" />
          </div>

          <div className="filter-check-controller ms-2">

            <div className="ms-2">
              <button type="button" className="zeconcile-btn btn transparent2 clr-purple dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                {status}
              </button>
              <ul className="dropdown-menu">
                <li className="dropdown-item">
                  <label className="label">
                    <input type="radio"
                      id="Expenses"
                      name="invoiceStatus"
                      value="Approved"
                      onClick={() => {
                        console.log("this.invoice is working");
                        this.setState(
                          { status: 1, page: 1, isStatusFilter: true },
                          () => {
                            this.getInvoices();
                          }
                        );
                      }}
                      className="form-check-input mx-2"

                    />Approved</label>
                </li>
                <li className="dropdown-item">
                  <label className="label">
                    <input type="radio"
                      id="Expenses"
                      name="invoiceStatus"
                      value="Unapproved"
                      onClick={() => {
                        this.setState(
                          { status: 0, page: 1, isStatusFilter: true },
                          () => {
                            this.getInvoices();
                            // console.log(this.getInvoices);
                          }
                        );
                      }}
                      className="form-check-input mx-2"

                    />
                    Un Approved</label>
                </li>

              </ul>
            </div>

            {/* old code  */}
          </div>

          <div className="col">

          </div>

          <div className="d-flex justify-content-end zc-invoice-list-controller pt-0">
            {/* </div>

          <div className="col-lg-4 d-flex justify-content-end"> */}
            <div className="zc-special-controller ">
              {/* <button
              className="zeconcile-btn btn mx-2 purple"

              >
                Delete Selected Invoice 
              </button> */}
              <button
                className="zeconcile-btn btn mx-2 purple"
                onClick={this.deleteAllInvoice}
              >
                Delete All Invoice
              </button>
              <button
                onClick={this.getAllInvoices}
                className="zeconcile-btn btn mx-2 purple"
              >
                {this.state.csvLoader
                  ? "Loading csv..."
                  : "Download Invoices"}
              </button>
              <CSVLink
                data={this.state.allInvoices}
                ref={this.csvLink}
                filename={`AllInvoices`}
              ></CSVLink>

              {/* <NavLink className="text-white text-decoration-none zeconcile-btn" to={"/debt-cred/" + this.props.params.id}>
                <span>
                  View all Debtors & Creditors
                </span>
                <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="Icon/ 18px/ Arrow Right">
                    <path id="Vector" d="M7.19629 14.3218L12.1963 9.32178L7.19629 4.32178" stroke="white" stroke-width="1.5" stroke-linecap="round" strokeLinejoin="round" />
                  </g>
                </svg>
              </NavLink> */}

              
                <button className="text-white text-decoration-none zeconcile-btn border-1" onClick={this.pageLimit}>
                  <span>
                    Add New Invoices
                  </span>
                </button>
            </div>
          </div>
        </div>

        <div className="row g-3 m-0 mb-3 py-0">
          <div className="scrollbar">
            {this.state.invoiceFound == "true" ||
              this.state.invoiceFound == true ? (
              <div style={{ height: "400px" }} className="d-flex m-auto bg-200 b-radius">
                <NavLink className="text-white align-middle text-decoration-none zeconcile-btn  m-auto" to={"/new-invoices/"}>
                  <span>
                    Add Your First invoice
                  </span>
                  {/* <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="Icon/ 18px/ Arrow Right">
                    <path id="Vector" d="M7.19629 14.3218L12.1963 9.32178L7.19629 4.32178" stroke="white" stroke-width="1.5" stroke-linecap="round" strokeLinejoin="round" />
                  </g>
                </svg> */}
                </NavLink>

              </div>
            ) : (this.state.invoiceFound == false || this.state.loader === true) ? (
              <table className="zeconcile-table">
                <thead>
                  <tr>
                    {/* <th>
                      <input type="checkbox" name="" id="" />
                    </th> */}
                    <th><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                    </svg>
                      Invoice Received Date</th>
                    <th ><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                    </svg>
                      Seller Name</th>
                    <th><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                    </svg>
                      Seller GST</th>
                    <th><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                    </svg>
                      Buyer Name</th>
                    <th><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                    </svg>
                      Buyer GST</th>
                    <th><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                    </svg>
                      Invoice Number</th>
                    <th><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                    </svg>
                      Invoice Date</th>
                    <th>
                      <div className="d-flex">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                        </svg>
                        IGST
                      </div>
                    </th>
                    <th>
                      <div className="d-flex">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                        </svg>
                        SGST
                      </div>
                    </th>
                    <th>
                      <div className="d-flex">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                        </svg>
                        CGST
                      </div>
                    </th>
                    <th><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                    </svg>
                      Invoice Value
                    </th>
                    <th><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M13.2263 8.54145C13.5192 8.83434 13.5192 9.30922 13.2263 9.60211L8.97637 13.8521C8.68348 14.145 8.2086 14.145 7.91571 13.8521L3.66571 9.60211C3.37282 9.30922 3.37282 8.83434 3.66571 8.54145C3.9586 8.24856 4.43348 8.24856 4.72637 8.54145L7.69604 11.5111V4.07178C7.69604 3.65757 8.03183 3.32178 8.44604 3.32178C8.86025 3.32178 9.19604 3.65757 9.19604 4.07178V11.5111L12.1657 8.54145C12.4586 8.24856 12.9334 8.24856 13.2263 8.54145Z" fill="#475569" />
                    </svg>
                      Status</th>
                    {/* <th scope="col">State Tax</th> */}
                    {/* <th scope="col">Total Tax</th> */}
                    {/* <th scope="col">Invoice Numbe</th> */}
                    {/* <th className="text-end" scope="col">Actions</th> */}
                  </tr>
                </thead>

                <tbody>
                  {this.state.invoices.map((item, index) => (

                    <tr key={index}>

                      <td>
                        {new Date(item.createdAt).toLocaleDateString()}
                      </td>
                      <td>
                        <NavLink to={"/invoice/" + item._id}>
                          {item.client_company ? (
                            item.client_company
                          ) : (
                            <p style={{ color: "red" }}>Details Missing</p>
                          )}
                        </NavLink>
                      </td>

                      <td>{item.seller_gst ? (
                        item.seller_gst
                      ) : (
                        <p style={{ color: "red" }}>GST Missing</p>

                      )}</td>
                      <td>{item.counter_party ? (
                        item.counter_party
                      ) : (
                        <p style={{ color: "red" }}>Details Missing</p>
                      )}</td>
                      <td>
                        {((item.buyer_gst) ? item.buyer_gst : item.counter_party_gst) ? (
                          item.buyer_gst ? item.buyer_gst : item.counter_party_gst
                        ) : (
                          <p style={{ color: "red" }}>GST Missing</p>

                        )}
                      </td>
                      <td>{item.inv_no}</td>

                      <td>{new Date(item.inv_date).toLocaleDateString()}</td>
                      <td>{item.taxes[0].IGST}</td>
                      <td>{item.taxes[0].SGST}</td>
                      <td>{item.taxes[0].CGST}</td>
                      <td>{item.grand_total}</td>
                      {/* <td>{item.subtotal}</td> */}
                      < td >
                        {(item.status === 1 || item.status === '1') ?
                          <span className="zeconcile-chip success">
                            <span className="dot"></span>
                            Approved
                          </span>
                          : (item.status === 0 || item.status === '0') ?
                            <span className="zeconcile-chip danger">
                              <span className="dot"></span>
                              Un Approved
                            </span> :
                            <span className="zeconcile-chip danger">
                              <span className="dot"></span>
                              Rejected
                            </span>
                        }
                      </td>
                    </tr>
                  ))}
                  {this.state.invoices.length < 1 &&
                    this.state.loader === false ? (
                    <tr key="-3">
                      <td colSpan="12">No Invoice Found</td>
                    </tr>
                  ) : this.state.loader === true ? (
                    <tr key="-2">
                      <td colSpan="12" style={{ textAlign: "center" }}>
                        <div className="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>

                {
                  this.state.totalPage > 1 ? (
                    <tfoot key="110">

                      <tr key="11001" className="p-2" >
                        <td colSpan="12" className="p-0 pt-3">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end ms-2">
                              {this.state.menuItems}
                            </ul>
                          </nav>
                        </td>
                      </tr>
                    </tfoot>

                  ) : (
                    ""
                  )
                }
              </table>
            ) : null}
          </div >
        </div >
      </>
    );
  }
}

export default navigationHook(InvoiceList);